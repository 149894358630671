import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AssignmentService } from '../../api/api/assignment.service';
import { TicketsForCustomerView } from '../../models/tickets-for-customer-view';
import { HttpLoaderService, SnackbarService } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-revoke-form',
  templateUrl: './customer-revoke-form.component.html',
  styleUrls: ['./customer-revoke-form.component.scss']
})
export class CustomerRevokeFormComponent implements OnInit {

  public customerId: number;
  public ticket: TicketsForCustomerView;
  public form: FormGroup;
  @Input('ticketId') public ticketId: number;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private assigmnet: AssignmentService,
    public dialogRef: MatDialogRef<CustomerRevokeFormComponent>,
    private loader: HttpLoaderService,
    private snackBar: SnackbarService,
    private translate: TranslateService
  ) { }

  ngOnInit(): void {
    this.customerId = this.passedData.customerId;
    this.ticket = this.passedData.ticket;

    this.form = new FormGroup({
      Reason: new FormControl('', [Validators.required,, Validators.minLength(5), Validators.maxLength(255)])
    });
  }

  saveRevoke() {
    this.loader.open();

    const formValues = this.form.value;
    var eventPlacementIds = [];
    eventPlacementIds.push(this.ticket.EventPlacementId);

    this.assigmnet.ticketCustomerRevoke(null, eventPlacementIds, formValues.Reason, this.customerId).subscribe(() => {
      this.loader.close();
      this.dialogRef.close(false);
      this.snackBar.open(this.translate.instant('GENERIC.SNACKBAR.STATUS-UPDATED'), 'GENERIC.SNACKBAR.CLOSE');
    }, (error) => {
      this.dialogRef.close(false);
      this.snackBar.error(this.translate.instant('CUSTOMER.REVOKE.ERROR-MESSAGE'));
      console.error(error);
    })
  }

}
