/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PrintType } from './printType';
import { PrintJobItemDetailsView } from './printJobItemDetailsView';


export interface PrintJobDetailsView { 
    PrintJobId?: number;
    PrintTypeId?: PrintType;
    TotalNrOfItems?: number;
    Items?: Array<PrintJobItemDetailsView> | null;
}

