/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductTypeEnum } from './productTypeEnum';
import { LinkedMembershipTypeEnum } from './linkedMembershipTypeEnum';


export interface GenerateReportArgs { 
    ReportId?: number | null;
    FileType?: string | null;
    TimeInterval?: number | null;
    TimeIntervalStart?: Date | null;
    TimeIntervalEnd?: Date | null;
    SellPriceType?: number | null;
    PriceFrom?: number | null;
    PriceTill?: number | null;
    EventId?: number | null;
    InitiativeId?: number | null;
    SeasonId?: number | null;
    MembershipId?: number | null;
    MatchDayPassId?: number | null;
    PassePartoutId?: number | null;
    Product?: number | null;
    ProductEnum?: ProductTypeEnum;
    PassePartoutSearch?: boolean;
    SalesChannelId?: number | null;
    TicketTypes?: Array<number> | null;
    LinkedMembershipId?: LinkedMembershipTypeEnum;
}

