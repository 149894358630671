/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiscountGenericTypeEnum } from './discountGenericTypeEnum';
import { DiscountTypeEnum } from './discountTypeEnum';
import { DiscountGeneralEnum } from './discountGeneralEnum';
import { DiscountProductTypeEnum } from './discountProductTypeEnum';
import { DiscountPriceGroupModel } from './discountPriceGroupModel';
import { DiscountCodeTypeEnum } from './discountCodeTypeEnum';


export interface DiscountModel { 
    Id?: number;
    Name?: string | null;
    Active?: boolean;
    ValidFrom?: Date | null;
    ValidTill?: Date | null;
    GenericCode?: boolean;
    GenericPrefix?: string | null;
    GenericLength?: number;
    GenericType?: DiscountGenericTypeEnum;
    CodeType?: DiscountCodeTypeEnum;
    DiscountProductType?: DiscountProductTypeEnum;
    GeneralDiscount?: DiscountGeneralEnum;
    NonStackable?: boolean;
    MaximumCount?: number | null;
    MaximumForOrder?: number | null;
    AmountPerc?: number;
    DiscountType?: DiscountTypeEnum;
    ShopDisplayName?: string | null;
    RestValueRemains?: boolean;
    EstimatedTotalCount?: number | null;
    DiscountCode?: string | null;
    EventIds?: Array<number> | null;
    EventTypeIds?: Array<number> | null;
    PassePartoutIds?: Array<number> | null;
    InitiativeIds?: Array<number> | null;
    ForTagIds?: Array<number> | null;
    ForDynamicTagIds?: Array<number> | null;
    MembershipIds?: Array<number> | null;
    LimitToPricing?: boolean;
    PriceGroups?: Array<DiscountPriceGroupModel> | null;
    RestrictToChannel?: boolean;
}

