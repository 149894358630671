import { Inject, Injectable } from "@angular/core";
import { BackofficeConfigModel, DeepLink } from "../api";
import { BASE_BO_CONFIG } from "../../injectors";

@Injectable()
export class DeepLinkHelperService {

  constructor(@Inject(BASE_BO_CONFIG) public config: BackofficeConfigModel) {
  }

  public get baseUrl() {
    return this.config.ticketShopURL;
  }

  public getUrl(deepLink: DeepLink) {
    return `${this.baseUrl}/${deepLink.Type}/${deepLink.Slug}`
  }

  public tryCopyUrl(deepLink: DeepLink) {
    try {
      navigator.clipboard.writeText(this.getUrl(deepLink));
      return true;
    }
    catch (ex) {
      console.error(ex);
      return false;
    }
  }
}
