/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TurnOverObject } from './turnOverObject';
import { TurnOverWeekDay } from './turnOverWeekDay';


export interface StatisticsBusinessDashboardTargetModel { 
    Created?: Date;
    TurnOverWeek?: number;
    TurnOverMonth?: number;
    TurnOverTotal?: number;
    Target?: number;
    RealizedTargetPerc?: number;
    TurnOverWeekDays?: Array<TurnOverWeekDay> | null;
    TurnOverAccountManagers?: Array<TurnOverObject> | null;
    TurnOverPassePartouts?: Array<TurnOverObject> | null;
    Expires?: Date;
    ExpiryInSeconds?: number;
    CacheTimeInSeconds?: number;
}

