/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductTypeEnum } from './productTypeEnum';
import { TicketStatusModel } from './ticketStatusModel';


export interface MarketplaceOrderLineView { 
    Id?: number;
    Price?: number;
    Total?: number;
    VAT?: number;
    TicketTypeId?: number;
    TicketTypeName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    TicketCustomer?: string | null;
    TicketCustomerId?: number;
    TicketCustomerType?: number;
    EventName?: string | null;
    EventId?: number | null;
    EventDate?: Date | null;
    ParentSectionName?: string | null;
    VATRateId?: number;
    VATRateDescription?: string | null;
    PassePartoutId?: number | null;
    PassePartoutName?: string | null;
    SelectedSingleEntranceId?: number | null;
    SelectedSingleEntranceName?: string | null;
    TicketText?: string | null;
    MembershipId?: number | null;
    MembershipName?: string | null;
    DiscountId?: number | null;
    DiscountDisplayName?: string | null;
    DiscountCodeId?: number | null;
    TotalWoDiscount?: number;
    MatchDayPassId?: number | null;
    MatchDayPassName?: string | null;
    MatchDayPassMaxTickets?: number | null;
    Barcode?: string | null;
    OrderId?: number;
    OrderLineId?: number;
    CustomerId?: number;
    TicketCustomerFirstName?: string | null;
    TicketCustomerMiddleName?: string | null;
    TicketCustomerLastName?: string | null;
    TicketCustomerInitials?: string | null;
    readonly TicketCustomerCalculatedName?: string | null;
    TicketCustomerIsIdentified?: boolean;
    CalculatedDiscount?: number;
    PriceVariationName?: string | null;
    TicketTypeIsPlaced?: boolean;
    EventPlacementId?: number | null;
    TicketTextId?: number | null;
    TicketCustomerNumber?: string | null;
    PriceVariationId?: number | null;
    ParentVenueBuildingBlockId?: number | null;
    Row?: number | null;
    Column?: number | null;
    GroupingId?: string | null;
    PassePartoutHardcard?: boolean;
    PassePartoutTypeCode?: string | null;
    InvoiceBatchId?: number | null;
    ProductType?: ProductTypeEnum;
    CrossSellProductId?: number | null;
    CrossSellProductName?: string | null;
    CrossSellProductVariantId?: number | null;
    CrossSellProductVariantName?: string | null;
    OriginalPrice?: number;
    Created?: Date;
    Modified?: Date | null;
    Deleted?: Date | null;
    CustomerPassePartoutId?: number | null;
    SectorName?: string | null;
    SectorId?: number | null;
    PurchasedWithMDPName?: string | null;
    IsCredited?: boolean;
    OrderCustomer?: string | null;
    OrderCustomerId?: number;
    OrderCustomerType?: number;
    OrderCustomerNumber?: string | null;
    OrderCustomerFirstName?: string | null;
    OrderCustomerMiddleName?: string | null;
    OrderCustomerLastName?: string | null;
    OrderCustomerInitials?: string | null;
    OrderCustomerIsIdentified?: boolean;
    readonly OrderCustomerCalculatedName?: string | null;
    TicketStatus?: TicketStatusModel;
    MarketplacePrice?: number | null;
    MarketplaceIBAN?: string | null;
    OriginalCustomerId?: number | null;
    OriginalCustomerName?: string | null;
    OriginalOrderId?: number | null;
    OriginalOrderDate?: Date | null;
    OriginalCustomerIsIdentified?: boolean;
    OriginalCustomerNumber?: string | null;
}

