/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MyTicketStatusEnum } from './myTicketStatusEnum';
import { MarketPlaceStatus } from './marketPlaceStatus';
import { TicketCategoryEnum } from './ticketCategoryEnum';
import { TicketStatusAvailableActions } from './ticketStatusAvailableActions';


export interface MobileTicketStatusModel { 
    Status?: MyTicketStatusEnum;
    MarketPlaceStatus?: MarketPlaceStatus;
    ActionRequired?: boolean;
    AvailableActions?: TicketStatusAvailableActions;
    Category?: TicketCategoryEnum;
}

