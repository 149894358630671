/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OwnerTypeEnum } from './ownerTypeEnum';
import { SeasonTypeEnum } from './seasonTypeEnum';
import { PassePartoutTypeEnum } from './passePartoutTypeEnum';


export interface PassePartoutMobileTicketingRequest { 
    CustomerNumber?: string | null;
    ExternalId?: string | null;
    SSOId?: string | null;
    IncludeDeliveryMethod?: boolean | null;
    OwnerType?: OwnerTypeEnum;
    PassePartoutType?: PassePartoutTypeEnum;
    SeasonInclusionType?: SeasonTypeEnum;
    IncludeImages?: boolean;
    IncludeEvents?: boolean | null;
    IncludeTags?: boolean | null;
    IncludeCustomerPhoto?: boolean | null;
}

