/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncrementalStatus } from './incrementalStatus';


export interface MembershipIncrementalView { 
    Created?: Date;
    Modified?: Date | null;
    Deleted?: Date | null;
    CreatedUtc?: Date;
    ModifiedUtc?: Date | null;
    DeletedUtc?: Date | null;
    CustomerMembershipId?: number;
    Status?: IncrementalStatus;
    MembershipCode?: string | null;
    CustomerId?: number;
    MembershipCustomerId?: number;
    StartDate?: Date;
    StartDateUtc?: Date;
    EndDate?: Date;
    EndDateUtc?: Date;
    PaymentMethodId?: number | null;
    PaymentMethodName?: string | null;
    SalesChannelId?: number | null;
    SalesChannelName?: string | null;
    Price?: number | null;
    PriceVariationId?: number | null;
    PriceVariationName?: string | null;
    PriceVariationIsDefault?: boolean | null;
    OrderId?: number | null;
    MembershipTypeId?: number | null;
    MembershipTypeName?: string | null;
    IsRecurring?: boolean;
    NewlyBought?: boolean;
    IsPaid?: boolean;
    RenewableInTicketshop?: boolean;
    MandatePresent?: boolean;
    CommunicationEmail?: string | null;
}

