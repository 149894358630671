/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PricingMethodEnum } from './pricingMethodEnum';


export interface DeliveryMethodWithPricingMethod { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    Name?: string | null;
    Code?: string | null;
    TranslationCode?: string | null;
    DefaultPricingMethodId?: number;
    IsPrintDefault?: boolean;
    AvailableUntilHoursBeforeEvent?: number | null;
    AvailableFromHoursBeforeEvent?: number | null;
    DefaultEnabled?: boolean;
    Price?: number;
    PriceComponentId?: number;
    IsSystemMethod?: boolean;
    CreateHardCard?: boolean;
    Instruction?: string | null;
    PricingMethod?: PricingMethodEnum;
    ShowInShop?: boolean;
    ShowExternal?: boolean;
    PricingMethodName?: string | null;
    PricingMethodTranslationCode?: string | null;
}

