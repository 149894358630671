/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PriceComponentDetailsView } from './priceComponentDetailsView';


export interface PriceComponentSchemeDetailsView { 
    Id?: number | null;
    Name?: string | null;
    Default?: boolean;
    IsActivated?: boolean;
    PriceComponents?: Array<PriceComponentDetailsView> | null;
}

