/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActivityTypeEnum } from './activityTypeEnum';
import { ActionTypeEnum } from './actionTypeEnum';


export interface ActivityLogView { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    RequestId?: string | null;
    TenantId?: string | null;
    TenantCode?: string | null;
    UserId?: number;
    ActivityType?: ActivityTypeEnum;
    ActionType?: ActionTypeEnum;
    ActionId?: number | null;
    IpAddress?: string | null;
    Firstname?: string | null;
    Lastname?: string | null;
    Middlename?: string | null;
}

