import { Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IdentificationCustomer, localDateLongPipe, localDateTimePipe, ProductTypeEnum, ReplaceOnEmptyPipe } from '@tymes4-shared';
import { CustomerSearchDialogComponent } from '../../components/customer/customer-search-dialog/customer-search-dialog.component';
import { ApplicationModuleHelperService } from '../../services/application-module.service';
import { OrderLinePositionDialogComponent } from '../../components/order-details/order-line-position-dialog/order-line-position-dialog.component';
import { Customer, CustomerService, EntranceService, EventPlacementPositionArgs, EventPlacementService, OrderLineViewWithPricing, VenueBuildingBlockEntranceView, OrderLineRevisionChange, OrderRevisionsService, EditOrderService } from '../../api';
import { OrderLinePriceDialogComponent } from '../../components/order-details/order-line-price-dialog/order-line-price-dialog.component';
import { AppUser } from '../../models/app-user';
import { AuthService } from '../../services/auth.service';
import { OrderLineRevision } from '../../api';
import { NgSelectComponent } from '@ng-select/ng-select';

@Component({
  selector: 'app-edit-order-line',
  templateUrl: './edit-order-line.component.html',
  styleUrls: ['./edit-order-line.component.scss'],
  providers: [ReplaceOnEmptyPipe]
})
export class EditOrderLineComponent implements OnInit {
  public orderLine: OrderLineViewWithPricing;
  public title: string;
  public isIbaActive: boolean = false;
  public noChanges: OrderLineRevision = {NewBarcode: true};
  public changes: OrderLineRevisionChange = {NewBarcode: true};
  public newPriceData = {selectedVariant: {}, manualPrice: null};
  public newCustomer: any;
  public newPosition: string;
  public newBarcode: boolean = false;
  public selectableEntrances: VenueBuildingBlockEntranceView[];
  public selectedEntranceName: string;
  public date: string;
  public localTime: Date = new Date();
  public showPriceHover: boolean = false;
  loggedInUser?: AppUser;
  public disabledItems: string[] = [];
  public customerCredit: number = 0;
  public customer: Customer;
  @ViewChild('priceFoldOut') priceFoldOut: ElementRef;
  @ViewChild('priceToggle') priceToggle: ElementRef;
  @ViewChild('entranceSelect') entranceSelect: NgSelectComponent;

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef: MatDialogRef<EditOrderLineComponent>,
    private dialog: MatDialog,
    private localDateTimePipe: localDateTimePipe,
    public translate: TranslateService,
    private applicationModuleHelperService: ApplicationModuleHelperService,
    private eventPlacementService: EventPlacementService,
    private entranceService: EntranceService,
    private localDatePipe: localDateLongPipe,
    private authService: AuthService,
    private orderRevisionsService: OrderRevisionsService,
    private editOrderService: EditOrderService,
    private customerService: CustomerService,
  ) {}

  ngOnInit() {
    this.loggedInUser = this.authService.getLoggedInUser();
    this.date = this.localDatePipe.transform(new Date().toDateString(), true);
    this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
      if (activeModules !== null) {
        this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
      }
    });

    this.orderLine = this.passedData.orderLine;
    this.changes.OrderLineId = this.orderLine.Id;
    this.getCustomerCredit(this.passedData.orderCustomerId);
    this.setPositionIsReadOnly(this.orderLine.Id);

    if (this.orderLine.ParentVenueBuildingBlockId) {
      this.entranceService.getAvailableInAllSections([this.orderLine.ParentVenueBuildingBlockId]).subscribe(resp => {
        let allEntrances = resp;

        // add the default
        const defaultText = {
          EntranceId: -999,
          EntranceName: this.translate.instant('SALES-ENTITY-MODIFICATION.LISTVIEW.STANDARD.DEFAULT-ON-EMPTY' )
        };
        for (let r of allEntrances){
          r.Entrances.splice(0, 0, defaultText);
        }

        this.selectableEntrances = allEntrances[0].Entrances;
      });
    }

    if (this.orderLine.ProductType == ProductTypeEnum.Ticket) {
      const eventDate = this.localDateTimePipe.transform(this.orderLine.EventDate, true); /* localDateTime parser */
      this.title = this.orderLine.PassePartoutName ? this.orderLine.PassePartoutName : eventDate + ' - ' + this.orderLine.EventName
    }
    else if (this.orderLine.ProductType == ProductTypeEnum.Membership) {
      this.title = this.orderLine.MembershipName;
      this.disabledItems = ['position', 'entrance'];
    }
    else if (this.orderLine.ProductType == ProductTypeEnum.DeliveryMethod) {
      this.title = 'T4.ORDERDETAILS.ORDERLINES.DELIVERY-COST'
      this.disabledItems = ['user', 'position', 'entrance', 'barcode'];
    }
    else if (this.orderLine.ProductType == ProductTypeEnum.PaymentMethod) {
      this.title = 'T4.ORDERDETAILS.ORDERLINES.MARKUP-PAYMENTMETHOD'
      this.disabledItems = ['user', 'position', 'entrance', 'barcode'];
    }
    else if (this.orderLine.ProductType == ProductTypeEnum.CrossSellProduct) {
      this.title = this.orderLine.CrossSellProductName + ' ' + this.orderLine.CrossSellProductVariantName + ' ' + this.orderLine.EventName;
      this.disabledItems = ['position', 'entrance', 'barcode'];
    }
    else if (this.orderLine.ProductType == ProductTypeEnum.MatchDayPass) {
      this.title = this.orderLine.MatchDayPassName
      this.disabledItems = ['position', 'entrance', 'barcode'];
    }

    this.customerService.getCustomer(this.passedData.orderCustomerId).subscribe(customer => {
      this.customer = customer;
    });
  }

  getCustomerCredit(customerId: number) {
    this.editOrderService.getCustomerCredit(customerId).subscribe(resp => this.customerCredit = resp.Credit);
  }

  setPositionIsReadOnly(orderLineId: number) {
    this.editOrderService.isOrderLineReadOnly(orderLineId).subscribe(isReadOnly => {
      if (isReadOnly) {
        this.disabledItems.push('position');
      }
    });
  }

  togglePriceHover() {
    this.showPriceHover = !this.showPriceHover;
  }

  openDialog(type: string) {
    if (type == 'customer') {
      this.customerDialog();
    }
    else if (type =='position') {
      this.positionDialog();
    }
    else if (type =='price') {
      this.priceDialog();
    }
  }

  constructCustomerIdentificationModel(customerData): IdentificationCustomer {
    if (customerData && customerData.TicketCustomerCalculatedName){
      return {
        CalculatedName: customerData.TicketCustomerCalculatedName,
        CustomerType: customerData.TicketCustomerType,
        CustomerName: customerData.TicketCustomer,
        IsIdentified: customerData.TicketCustomerIsIdentified,
        CustomerNumber: customerData.TicketCustomerNumber,
        TicketCustomerId: customerData.TicketCustomerId,
      }
    }
    else if (customerData) {
      return {
        CalculatedName: customerData.CalculatedName,
        CustomerType: customerData.CustomerType,
        CustomerName: customerData.Name,
        IsIdentified: customerData.IsIdentified,
        CustomerNumber: customerData.CustomerNumber,
        TicketCustomerId: customerData.Id,
      }
    }
  }

  customerDialog() {
    let dialogRef: MatDialogRef<any> = this.dialog.open(CustomerSearchDialogComponent, {
      height: '95%',
      width: '98vw',
      maxWidth: '98vw',
      disableClose: true,
      data: { }
    });
    dialogRef.afterClosed()
      .subscribe(customer => {
        if (customer && customer.Id != this.orderLine.CustomerId) {
          this.changes.CustomerId = customer.Id;
          this.newCustomer = customer;
        }
        else if (customer) {
          this.changes.CustomerId = null;
          this.newCustomer = null;
        }
      }
    );
  }

  getNumberOfChanges() {
    return Object.keys(this.changes).filter((key)=> this.changes[key] != null && this.changes[key] != false && key != "OrderLineId" ).length;
  }

  onSelectedSingleEntranceChange(ev: VenueBuildingBlockEntranceView) {
    if (ev == null)
      return;
    
    if (this.orderLine.SelectedSingleEntranceId == ev.EntranceId){
      this.changes.EntranceId = null;
      this.selectedEntranceName = null;
      this.entranceSelect.handleClearClick();
    }
    else {
      this.changes.EntranceId = ev.EntranceId;
      this.selectedEntranceName = ev.EntranceName;
    }
  }

  positionDialog() {
    let dialogRef: MatDialogRef<any> = this.dialog.open(OrderLinePositionDialogComponent, {

      width: '33.333%',
      minWidth: '740px',
      disableClose: true,
      data: {
        customer: this.newCustomer ? this.newCustomer : this.orderLine.CustomerId,
        orderLine: this.orderLine,
        positionType: this.orderLine.PassePartoutId ? 'P' : 'E'
      }
    });
    dialogRef.afterClosed()
      .subscribe(position => {
        if (!position) {
          return;
        }
        if (position == "free") {
          this.dialogRef.close();
          return;
        }

        if (this.changes.CustomerPassePartoutId || this.changes.EventPlacementId)
          this.clearPosition();

        let args: EventPlacementPositionArgs = {
          ParentVBBId: position[1],
          TicketTypeId: position[0].TicketTypeId,
          Row: position[0].Row,
          Column: position[0].Column,
          CustomerId: this.changes.CustomerId ?? this.orderLine.CustomerId
        }
        if (this.orderLine.EventId)
          args.EventIds = [this.orderLine.EventId]
        else{
          args.PassePartoutIds = [this.orderLine.PassePartoutId];
          args.CustomerPassePartoutId = this.orderLine.CustomerPassePartoutId;
        }

        this.eventPlacementService.reserveEventPlacements(args).subscribe(resp => {
          this.newPosition = resp.ParentName +  ' / ' + (resp.RowNumber ? resp.RowNumber : '-') + ' / ' + (resp.SeatNumber ? resp.SeatNumber : '-');
          if (this.orderLine.EventId) {
            this.changes.EventPlacementId = resp.EventPlacementId;
          }
          else {
            this.changes.CustomerPassePartoutId = this.orderLine.CustomerPassePartoutId;
          }
        });
      }
    );
  }

  priceDialog() {
    const dialogRef: MatDialogRef<any> = this.dialog.open(OrderLinePriceDialogComponent, {
      width: '999px',
      maxWidth: '98vw',
      disableClose: true,
      data: {
        orderLine: this.orderLine,
        customer: this.customer,
      }
    });
    dialogRef.afterClosed()
      .subscribe(resp => {
        if (resp) {
          this.changes.AppliedCredit = resp.appliedCredit;
          this.changes.PriceVariationId = resp.selectedVariant.VariantId;
          this.changes.ManualPriceChange = resp.manualPriceChange;
          if (resp.manualPriceChange)
            this.changes.ManualPrice = resp.manualPriceChange.reduce((prev, curr) => prev + curr.AmountInVAT, 0);

          this.newPriceData = resp;
          this.newPriceData.manualPrice = this.changes.ManualPrice;
        }
      }
    );
  }

  clearPosition() {
    this.eventPlacementService.clearPositions(this.changes.CustomerPassePartoutId, this.changes.EventPlacementId).subscribe(() => {
      this.changes.EventPlacementId = null;
      this.changes.CustomerPassePartoutId = null;
      this.newPosition = null;
    })
  }

  cancel(data: string) {
    if (data == 'user') {
      this.changes.CustomerId = null;
      this.newCustomer = null;
    }
    else if (data == 'position') {
      this.clearPosition();
    }
    else if (data == 'entrance') {
      this.changes.EntranceId = null;
      this.selectedEntranceName = null;
      this.entranceSelect.handleClearClick();
    }
    else if (data == 'price') {
      this.changes.PriceVariationId = null;
      this.changes.AppliedCredit = null;
      this.changes.ManualPrice = null;
      this.newPriceData = {selectedVariant: {}, manualPrice: null};
      this.showPriceHover = false;
    }
  }

  submit() {
    this.orderRevisionsService.createOrderRevision(this.changes).subscribe(revisionId => {
      this.changes.Id = revisionId;
      this.dialogRef.close();
    });
  }

  calculateLineBalance() {
    const line = this.changes
    if (line?.ManualPrice !== null) {
      return this.orderLine.Total - line.ManualPrice;
    }

    if (line?.PriceVariationId !== null) {
      return this.orderLine.Total - line.PriceVariationId;
    }

    return null;
  }

  priceIsChanged() {
    return this.changes.PriceVariationId || this.changes.AppliedCredit || this.changes.ManualPrice;
  };

  hasChanges = () => Object.keys(this.changes).some(key => this.changes[key] !== this.noChanges[key]);

  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (this.priceFoldOut && !this.priceFoldOut.nativeElement.contains(event.target) && !this.priceToggle.nativeElement.contains(event.target)) {
      this.showPriceHover = false;
    }
  }
}
