/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculatedPriceComponentModel } from './calculatedPriceComponentModel';


export interface OrderLineRevisionChange { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    OrderLineId?: number;
    CustomerId?: number | null;
    EventPlacementId?: number | null;
    CustomerPassePartoutId?: number | null;
    EntranceId?: number | null;
    PriceVariationId?: number | null;
    AppliedCredit?: number | null;
    ManualPrice?: number | null;
    NewPrice?: number | null;
    NewBarcode?: boolean;
    Comment?: string | null;
    UserId?: number | null;
    UserCalculatedName?: string | null;
    ManualPriceChange?: Array<CalculatedPriceComponentModel> | null;
}

