import { Component, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { HttpLoaderService, SessionService } from '@tymes4-shared';
import { TranslateService } from '@ngx-translate/core';
import { StatisticsService } from '../../api/api/statistics.service';
import { StatisticsShopDashboardModel } from '../../api/model/statisticsShopDashboardModel';
import { TurnOverPerPeriod } from '../../api';
import * as moment from 'moment-timezone';

@Component({
  selector: 'app-statistics-dashboard-shop',
  templateUrl: './statistics-dashboard-shop.component.html',
  styleUrls: ['./statistics-dashboard-shop.component.scss']
})
export class StatisticsDashboardShopComponent implements OnInit, OnDestroy, AfterViewInit {

  Math = Math;

  public statistics : StatisticsShopDashboardModel = null;

  salesPerProductOptions = null;
  turnoverSeries = null;
  soldTicketsSeries = null;
  orderValueSeries = null;
  turnoverOptions = null;
  activityOptions = null;
  refreshTimerOptions = null;
  selectableIntervals = [];
  selectableChannels = [];
  intervalValue = null;
  channelValue = null;
  refreshTimer = null;

  constructor (private statisticsService: StatisticsService,
               private translate: TranslateService,
               private sessionService: SessionService,
               private loader: HttpLoaderService) { }

  ngOnInit() {
    this.intervalValue = this.getSelectedInterval();

    this.selectableIntervals.push ({text: this.translate.instant('GENERIC.INTERVALS.LASTHOUR'), Id: 'H'});
    this.selectableIntervals.push ({text: this.translate.instant('GENERIC.INTERVALS.TODAY'), Id: 'D'});
    this.selectableIntervals.push ({text: this.translate.instant('GENERIC.INTERVALS.YESTERDAY'), Id: 'Y'});
    this.selectableIntervals.push ({text: this.translate.instant('GENERIC.INTERVALS.7DAYS'), Id: 'W'});

    this.channelValue = this.getSelectedChannel();

    this.selectableChannels.push ({text: this.translate.instant('DASHBOARD.STATS.CHANNEL.DROPDOWN.SHOP-R'), Id: '1'});
    this.selectableChannels.push ({text: this.translate.instant('DASHBOARD.STATS.CHANNEL.DROPDOWN.SHOP-SEC'), Id: '4'});
    this.selectableChannels.push ({text: this.translate.instant('DASHBOARD.STATS.CHANNEL.DROPDOWN.SHOP-TOT'), Id: '5'});
    this.selectableChannels.push ({text: this.translate.instant('DASHBOARD.STATS.CHANNEL.DROPDOWN.BO'), Id: '2'});
    this.selectableChannels.push ({text: this.translate.instant('DASHBOARD.STATS.CHANNEL.DROPDOWN.FAN'), Id: '3'});
    this.selectableChannels.push ({text: this.translate.instant('DASHBOARD.STATS.CHANNEL.DROPDOWN.TOT'), Id: '-1'});
  }

  ngAfterViewInit() {
    this.loadCharts();
  }

  ngOnDestroy() {
    this.stopTimer();
  }

  stopTimer() {
    if (this.refreshTimer !== null) {
      clearInterval(this.refreshTimer);
    }
  }

  getSelectedInterval() {
    let result = 'D';

    if (sessionStorage.getItem['userStatisticsInterval'] && sessionStorage.getItem['userStatisticsInterval'] !== null) {
      result = sessionStorage.getItem['userStatisticsInterval'];
    }

    return result;
  }

  onIntervalChange(e) {
    this.intervalValue = e.Id;
    sessionStorage.setItem('userStatisticsInterval', this.intervalValue);
    this.loadCharts();
  }

  getSelectedChannel() {
    let result = '1';

    if (sessionStorage.getItem['userStatisticsChannel'] && sessionStorage.getItem['userStatisticsChannel'] !== null) {
      result = sessionStorage.getItem['userStatisticsChannel'];
    }

    return result;
  }

  onChannelChange(e) {
    this.channelValue = e.Id;
    sessionStorage.setItem('userStatisticsChannel', this.channelValue);
    this.loadCharts();
  }

  loadCharts(): any {
    this.loader.open();

    this.stopTimer();

    let channel = 'shop';
    if (this.channelValue === '4') { channel = 'shopsec';} 
    else if (this.channelValue === '5') { channel = 'shoptot';} 
    else if (this.channelValue === '2') { channel = 'bo';} 
    else if (this.channelValue === '3') { channel = 'fanglobal';} 
    else if (this.channelValue === '-1') { channel = 'shopbo';}

    this.statisticsService.getDashboardStatistics(channel, this.intervalValue, false).subscribe((data : StatisticsShopDashboardModel) => {
    //this.statisticsServiceOld.getDashboardStatistics(channel, this.intervalValue, false).subscribe((data) => {
      this.statistics = data;

      this.loader.close();

      setTimeout(() => {

        this.refreshTimer = setInterval(() => {
          this.updateRefreshTimer();
        }, 1000);

        this.initCharts();
      }, 50);
    }, (error) => {
      this.loader.close();
    });
  }

  initCharts(): any {
    if(this.statistics.TurnOverPeriods != null) {
      this.turnoverSeries = [{
        data: this.statistics.TurnOverPeriods.map(t => t.Turnover)
      }];
  
      this.soldTicketsSeries = [{
        data: this.statistics.TurnOverPeriods.map(t => t.SoldTicketAmount)
      }];
  
      this.orderValueSeries = [{
        data: this.statistics.TurnOverPeriods.map(t => t.AverageOrderValue)
      }];
  
      this.initSalesPerProduct();
      this.initTurnoverChart();
      this.initAccountActivity();
    }
  }

  updateRefreshTimer() {
    if (this.statistics === null) {
      return;
    }

    // substract second
    this.statistics.ExpiryInSeconds--;

    if (this.statistics.ExpiryInSeconds < 0) {
      // we need to refresh
      this.loadCharts();
    }
  }

  private getCategoryNames(periods: TurnOverPerPeriod[]) {
    let language = this.sessionService.getLanguage();
    let format = this.statistics.Interval == "W" || this.statistics.Interval == "180D" ? language.FormatDate : language.FormatTime;
    return periods.map(period => moment.utc(period.Start).tz(language.TimeZone).format(format));
  }

  initSalesPerProduct() {
    const categories = this.getCategoryNames(this.statistics.TurnoverPerProduct && this.statistics.TurnoverPerProduct.length > 0 ? this.statistics.TurnoverPerProduct[0].Periods : this.statistics.TurnOverPeriods);
    this.salesPerProductOptions = {
      chart: {
        height: 275,
        type: 'bar',
        toolbar: {
          show: false
        },
      },
      colors: ['#9e6de0', '#487EBC', '#faafca', '#6493fa', '#ea4c89', '#00aff0', '#1d1f26', '#46c79e', '#31343d'],
      plotOptions: {
        bar: {
          horizontal: false,
          endingShape: 'flat',
          columnWidth: '55%',
        },
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ['transparent']
      },
      series: this.statistics.TurnoverPerProduct.map(p => ({
        name: p.ProductName ? p.ProductName : '',
        data: p.Periods.map(pe => pe.SoldTicketAmount)
      })),
      xaxis: {
        categories: categories,
      },
      yaxis: {
        show: false
      },
      fill: {
        opacity: 1

      },
      tooltip: {
        theme: 'dark',
        x: {
          show: false
        },
        y: {
          formatter: function(val) {
            return '' + val;
          }
        },
        marker: {
          show: true,
        }
      }
    };
  }

  initTurnoverChart() {
    this.turnoverOptions = {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false
        },
      },
      stroke: {
        width: [2, 3],
        curve: 'smooth',
        dashArray: [0, 5]
      },
      plotOptions: {
        horizontal: false,
      },
      colors: ['#46c79e'],
      series: [{
        name: this.translate.instant('DASHBOARD.STATS.LABEL.TURNOVER') ,
        data: this.statistics.TurnOverPeriods.map(t => t.Turnover)
      }],
      markers: {
        size: [5, 0]
      },
      xaxis: {
        categories: this.getCategoryNames(this.statistics.TurnOverPeriods),
        axisBorder: {
          show: false,
        },
        labels: {
          show: true
        },
        axisTicks: {
          show: false
        }
      },
      tooltip: {
        theme: 'dark',
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
            formatter: function(value, { seriesIndex, dataPointIndex, w }) {
              return '€ ' + value.toFixed(2); // (Math.round(value * 100) / 100)
            }
        },
        marker: {
          show: true,
        },
      },
      legend: {
        show: false
      },
    };
  }

  initAccountActivity (){
    this.activityOptions = {
      chart: {
        height: 350,
        type: 'line',
        toolbar: {
          show: false
        },
      },
      stroke: {
        width: [2, 3],
        curve: 'smooth',
        dashArray: [0, 5]
      },
      plotOptions: {
        horizontal: false,
      },

      colors: ['#487EBC', '#faafca', '#31343d'],
      series: [{
        name: this.translate.instant('DASHBOARD.STATS.LABEL.LOGINS') ,
        data: this.statistics.TurnOverPeriods.map (t => t.AccountLogins)
      }, {
        name: this.translate.instant('DASHBOARD.STATS.LABEL.ACTIVATIONS'),
        data: this.statistics.TurnOverPeriods.map (t => t.AccountActivations)
      }, {
        name: this.translate.instant('DASHBOARD.STATS.LABEL.PWD-RESETS'),
        data: this.statistics.TurnOverPeriods.map (t => t.AccountPWDResets)
      }],
      markers: {
        size: [5, 0]
      },
      xaxis: {
        categories: this.getCategoryNames(this.statistics.TurnOverPeriods),
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false
        }
      },
      tooltip: {
        theme: 'dark',
        shared: true,
        intersect: false,
        fixed: {
          enabled: false
        },
        x: {
          show: false
        },
        y: {
          formatter: function(value, { seriesIndex, dataPointIndex, w }) {
            return value.toFixed(0); // (Math.round(value * 100) / 100)
          }
        },
        marker: {
          show: true,
        },
      },
      legend: {
        show: false
      },
    };
  }
}
