/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductTypeEnum } from './productTypeEnum';


export interface OrderLine { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    Price?: number;
    OrderId?: number;
    EventPlacementId?: number | null;
    CustomerId?: number;
    VAT?: number;
    Total?: number;
    VATRateId?: number;
    CustomerPassePartoutId?: number | null;
    TicketTypeId?: number | null;
    SeatNumber?: string | null;
    RowNumber?: string | null;
    ParentVenueBuildingBlockId?: number | null;
    GroupingId?: string | null;
    EventId?: number | null;
    Row?: number | null;
    Column?: number | null;
    OriginalPrice?: number;
    SelectedSingleEntranceId?: number | null;
    TicketTextId?: number | null;
    PriceVariationId?: number | null;
    ReservationLineId?: number | null;
    PrintBatchId?: number | null;
    InvoiceBatchId?: number | null;
    ProductType?: ProductTypeEnum;
    CustomerMembershipId?: number | null;
    CrossSellProductVariantId?: number | null;
    AccountingExportDate?: Date | null;
    AccountingExportInvoiceId?: string | null;
    AccountingExportMessage?: string | null;
    TotalWoDiscount?: number;
    CustomerMatchDayPassId?: number | null;
    IsCredited?: boolean;
}

