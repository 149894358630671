  <!-- MODAL HEADER-->
  <div class="row modal-bar mb-5">

    <div class="col-sm-6">
        <h5 class="modal-title">{{ passedData.title }}</h5>
    </div>

    <div class="col-sm-6">

        <div class="modal-actions">

            <div class="modal-close">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span (click)="dialogRef.close(false);">×</span>
                </button>
            </div>
        </div>
    </div>
  </div>

  <t4-list-view #listVw [options]="listOptions" (onDataRequest)="OnDataRequest($event)">

    <ng-container *t4ListItem="let item;">



      <td>{{item.EventPlacementId }}</td>
      <td>{{item.EventStartDateTime | localDateTime | slice:0:10 }}</td>
      <td>{{item.EventStartDateTime | localTime}}</td>
      <td>{{item.EventName }}</td>
      <td>{{item.ParentShortName }}</td>
      <td>{{ item.RowNumber | replaceOnEmpty }} / {{ item.SeatNumber | replaceOnEmpty}}</td>
      <td>{{item.Barcode}}</td>
      <td *ngIf="this.secondaryTicketingActive"><span [ngClass]="getTicketSwapStatusClass(item.LatestTicketSwapStatus)">{{getTicketSwapStatus(item.LatestTicketSwapStatus) | translate}}</span></td>
      <td>{{item.DeletionReason  | replaceOnEmpty }}</td>
      <td>{{item.DeletedByUserFullname  | replaceOnEmpty }} </td>


      <td class="grid-action">
        <button *ngIf="ibaActive" [disabled]="item.CanRevokeInBackoffice === false" (click)="revoke(item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-step-backward" ></button>
        <button *ngIf="ibaActive" (click)="openAssignmentHistory(item.EventPlacementId)" type="button" class="btn btn-grid btn-round mdi mdi-primary mdi-history"></button>
        <button [disabled]="!this.itemDeletable(item)" *ngIf="item.Deleted == false" (click)="confirmDelete(item)" type="button" class="btn btn-grid btn-round mdi mdi-danger mdi-trash-can-outline"></button>
        <span class="mr-3" *ngIf="item.Deleted == true" style="color: red;">{{'GENERIC.DELETED' | translate}}</span>
      </td>





    </ng-container>

  </t4-list-view>



