/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodModel } from './paymentMethodModel';
import { EventForPassePartoutView } from './eventForPassePartoutView';


export interface PassePartoutDetail { 
    Id?: number;
    Active?: boolean;
    Name?: string | null;
    Code?: string | null;
    PassePartoutType?: string | null;
    PrecedingPassePartout?: string | null;
    Events?: Array<EventForPassePartoutView> | null;
    NrOfEvents?: number;
    PaymentMethods?: Array<PaymentMethodModel> | null;
    SeasonId?: number | null;
    IdentificationRequired?: boolean;
    PassePartoutTypeId?: number | null;
    Named?: boolean;
    IsUniquelyAssigned?: boolean;
    PhotoRequired?: boolean;
    HasSoldOut?: boolean;
    ApplyIndexing?: boolean;
    PrecedingPassePartoutId?: number | null;
    KeepPrecedingBarcode?: boolean;
    HardcardTemplateId?: number | null;
    ExtraInfo?: string | null;
    MobileTicketFrontTemplateId?: number | null;
    MobileTicketBackTemplateId?: number | null;
    MaxResell?: number | null;
    MaxSwap?: number | null;
    LimitResell?: boolean;
    AccountingDimensionCode1?: string | null;
    AccountingDimensionCode2?: string | null;
    AccountingDimensionCode3?: string | null;
    AccountingDimensionCode4?: string | null;
    AccountingDimensionCode5?: string | null;
    AdditionalAttribute?: string | null;
    ShowMobileTicketInTicketshop?: boolean;
    MaxSeasonCardAmount?: number | null;
    LimitSeasonCardAmount?: boolean;
}

