/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculatedPriceVariantModel } from './calculatedPriceVariantModel';


export interface SeatPlanPositionEventDetails { 
    Id?: number;
    PlacementTypeId?: number;
    Row?: number;
    Column?: number;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    OrderId?: number | null;
    OrderDate?: Date | null;
    CustomerName?: string | null;
    CustomerNumber?: string | null;
    PassePartoutId?: string | null;
    PassePartoutName?: string | null;
    EventId?: number;
    EventName?: string | null;
    EventStartDateTime?: Date | null;
    Unavailable?: boolean;
    Sold?: boolean;
    Reserved?: boolean;
    ReservationHexColor?: string | null;
    ReservationTypeName?: string | null;
    PriceVariants?: Array<CalculatedPriceVariantModel> | null;
    ContingentId?: number | null;
    ContingentName?: string | null;
    SeatRestrictionId?: number | null;
    SeatRestrictionName?: string | null;
    HasAccessToContingent?: boolean;
    ImageUrlAwayTeam?: string | null;
    ImageUrlHomeTeam?: string | null;
    SecondaryOrderId?: number | null;
}

