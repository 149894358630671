import {Component, EventEmitter, Input, Output} from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 't4-timespan-control',
  templateUrl: 'timespan-control.component.html', 
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi:true,
    useExisting: TimeSpanControlComponent
  }]
})
export class TimeSpanControlComponent implements ControlValueAccessor {
  
  public timeSpanText:string = '';

  onChange = (dte: string) => {};
  onTouched = () => {};
  touched = false;
  disabled = false;

  @Input() required: boolean = true;
  @Input() labelTimeSpan: string = ''; 
  @Input() timeSpanPlaceholder: string = 'ddd.hh:mm:ss';
  @Input() colWidth: number = 6;

  @Output() changeEvent = new EventEmitter<string>();

  public formControl: FormControl = new FormControl();

  constructor() {
  }

  writeValue(dte: string) {
    this.timeSpanText = dte;
    this.formatText();
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  formatText() {
    if(this.timeSpanText === null || this.timeSpanText === undefined) {
      return;
    }

    const timeSpanRegex = /^((?<DAYS>\d{1,})\.)?(?<HOURS>\d{1,2})(:(?<MINUTES>\d{1,2}))?(:(?<SECONDS>\d{1,2}))?$/;
    var match = this.timeSpanText.match(timeSpanRegex);
    if(!match) {
      return;
    }

    const { DAYS, HOURS, MINUTES, SECONDS } = match['groups'] || {};
    parseInt(DAYS)
    parseInt(HOURS) > 24
    parseInt(MINUTES) > 60
    parseInt(SECONDS) > 60

    const days = DAYS || '0';
    let hours = HOURS?.padStart(2, '0') || '00';
    let minutes = MINUTES?.padStart(2, '0') || '00';
    let seconds = SECONDS?.padStart(2, '0') || '00';
    if(parseInt(hours) >= 24) {
      hours = "23"
    }
    if(parseInt(minutes) >= 60) {
      minutes = "59"
    }
    if(parseInt(seconds) >= 60) {
      seconds = "59"
    }
        
    this.timeSpanText = days + '.' + hours + ':' + minutes + ':' + seconds
  }

  capValues() {

  }

  onTimeSpanChange(e: string) {
    this.formatText();
    this.markAsTouched();
    this.onChange(this.timeSpanText);
    this.changeEvent.emit(this.timeSpanText);
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}