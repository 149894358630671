/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketSwapStatusEnum } from './ticketSwapStatusEnum';


export interface AssignmentHistoryView { 
    Id?: number;
    Created?: Date | null;
    Status?: TicketSwapStatusEnum;
    Barcode?: string | null;
    EventPlacementId?: number;
    EventName?: string | null;
    BoughtById?: number;
    BoughtByNumber?: string | null;
    BoughtByName?: string | null;
    TicketHolderId?: number;
    TicketHolderNumber?: string | null;
    TicketHolderName?: string | null;
    ReceivingCustomerId?: number;
    ReceivingCustomerName?: string | null;
    ByCalculated?: string | null;
    ToCalculated?: string | null;
}

