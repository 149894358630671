/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PaymentMethodModel } from './paymentMethodModel';
import { AddressModel } from './addressModel';
import { ContactModel } from './contactModel';


export interface CustomerModel { 
    Id?: number | null;
    CustomerType?: number;
    Address: AddressModel;
    PrimaryContact: ContactModel;
    Contacts?: Array<ContactModel> | null;
    PaymentMethods?: Array<PaymentMethodModel> | null;
    PostalAddressId?: number | null;
    InvoiceAddressId?: number | null;
    Name?: string | null;
    Email?: string | null;
    COC?: string | null;
    VATNr?: string | null;
    TelephoneNr?: string | null;
    ExternalId?: string | null;
    ExternalCode?: string | null;
    AccessToDistributix?: boolean;
    CompletedRegistration?: boolean | null;
    PaysByInvoice?: boolean | null;
    CustomerNumber?: string | null;
    AccountManagerId?: number | null;
    TotalCredit?: number | null;
}

