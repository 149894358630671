<div class="row justify-content-between mb-5 " *ngIf="statistics !== null">
  <div class="col-lg-8">
    <div class="row">
      <div class="col-md-4">
        <div class="mini-status">
          <div class="text-content text-primary">
            <span class="title" style="width: 153px;">{{ 'DASHBOARD.QUICK-STATS.HEADER.TURNOVER' | translate }}</span>
            <span class="status" ><i class="mdi mdi-google-analytics"></i>
              {{ statistics.Turnover | number : '1.2-2' }}
            </span>
          </div>
          <div class="chart-content">
            <t4-dash-small-chart color="#487EBC" [series]="turnoverSeries"></t4-dash-small-chart>
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="mini-status">
          <div class="text-content ">
            <span class="title" style="width: 153px;">{{ 'DASHBOARD.QUICK-STATS.HEADER.TICKET' | translate }}</span>
            <span class="status text-success"><i class="mdi mdi-progress-upload"></i>{{ statistics.SoldTickets | number }}</span>
          </div>

          <div class="chart-content">
            <t4-dash-small-chart color="#46c79e" [series]="soldTicketsSeries"></t4-dash-small-chart>
          </div>

        </div>
      </div>
      <div class="col-md-4">
        <div class="mini-status">
          <div class="text-content">
            <span class="title" style="width: 153px;">{{ 'DASHBOARD.QUICK-STATS.HEADER.ORDERVALUE' | translate }}</span>
            <span class="status text-info"><i class="mdi mdi-cart"></i>{{ statistics.AverageOrderValue | number : '1.2-2' }} </span>
          </div>
          <div class="chart-content">
            <t4-dash-small-chart color="#04c7e0" [series]="orderValueSeries"></t4-dash-small-chart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 d-flex justify-content-xl-end flex-column flex-wrap align-items-lg-end pb-1">

    <ng-select [items]="selectableIntervals" bindLabel="text" bindValue="Id" [(ngModel)]='intervalValue' (change)="onIntervalChange($event)" [clearable]="false" notFoundText="{{ 'GENERIC.DROPDOWN.NOT-FOUND-TEXT' | translate }}"></ng-select>

    <ng-select [items]="selectableChannels" bindLabel="text" bindValue="Id" [(ngModel)]='channelValue' (change)="onChannelChange($event)" [clearable]="false" notFoundText="{{ 'GENERIC.DROPDOWN.NOT-FOUND-TEXT' | translate }}"></ng-select>

    <span class="justify-content-left">{{ 'DASHBOARD.QUICK-STATS.HEADER.UPDATED-IN' | translate : {expiry: this.statistics.ExpiryInSeconds } }}</span>
    
  </div>
</div>

<div class="row">
  <div class="col-xl-12">

    <!-- User Acquisition Statistics -->
    <div class="card card-default" id="user-acquisition">
      <div class="card-header ">
        <h2>{{ 'DASHBOARD.STATS.HEADER.SALES-PER-PRODUCT' | translate }}</h2>
      </div>

      <div class="tab-content" id="myTabContent">
        <apx-chart  [chart]="salesPerProductOptions.chart" *ngIf="salesPerProductOptions !== null && salesPerProductOptions !== undefined"
                    [stroke]="salesPerProductOptions.stroke"
                    [colors]="salesPerProductOptions.colors"
                    [plotOptions]="salesPerProductOptions.plotOptions"
                    [series]="salesPerProductOptions.series"
                    [xaxis]="salesPerProductOptions.xaxis"
                    [yaxis]="salesPerProductOptions.yaxis"
                    [fill]="salesPerProductOptions.fill"
                    [tooltip]="salesPerProductOptions.tooltip"
                    [dataLabels]="salesPerProductOptions.dataLabels">
          </apx-chart>
      </div>

    </div>

  </div>

</div>


<div class="row">
  <div class="col-xl-6">

    <!-- User -->
    <div class="card card-default">
      <div class="card-header">
        <h2>{{ 'DASHBOARD.STATS.HEADER.TURNOVER-DEVELOPMENT' | translate }}</h2>
      </div>
      <div class="card-body">
        <apx-chart  [chart]="turnoverOptions.chart" *ngIf="turnoverOptions !== null"
                    [stroke]="turnoverOptions.stroke"
                    [colors]="turnoverOptions.colors"
                    [plotOptions]="turnoverOptions.plotOptions"
                    [series]="turnoverOptions.series"
                    [xaxis]="turnoverOptions.xaxis"
                    [markers]="turnoverOptions.markers"
                    [legend]="turnoverOptions.legend"
                    [tooltip]="turnoverOptions.tooltip">
        </apx-chart>
      </div>
    </div>

  </div>
  <div class="col-xl-6">

    <!-- Session -->
    <div class="card card-default">
      <div class="card-header">
        <h2>{{ 'DASHBOARD.STATS.HEADER.ACTIVITY' | translate }}</h2>
      </div>
      <div class="card-body">
        <ng-container *ngIf="activityOptions !== null">

          <apx-chart  [chart]="activityOptions.chart"
                      [stroke]="activityOptions.stroke"
                      [colors]="activityOptions.colors"
                      [plotOptions]="activityOptions.plotOptions"
                      [series]="activityOptions.series"
                      [xaxis]="activityOptions.xaxis"
                      [markers]="activityOptions.markers"
                      [legend]="activityOptions.legend"
                      [tooltip]="activityOptions.tooltip">
          </apx-chart>

        </ng-container>

      </div>
      </div>
    </div>

  </div>
