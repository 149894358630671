/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export type AuthenticateContactResultEnum = 'Ok' | 'ActivationRequired' | 'InvalidLogin' | 'Locked' | 'LastAttempt' | 'SSONotFound' | 'RedirectNeeded';

export const AuthenticateContactResultEnum = {
    Ok: 'Ok' as AuthenticateContactResultEnum,
    ActivationRequired: 'ActivationRequired' as AuthenticateContactResultEnum,
    InvalidLogin: 'InvalidLogin' as AuthenticateContactResultEnum,
    Locked: 'Locked' as AuthenticateContactResultEnum,
    LastAttempt: 'LastAttempt' as AuthenticateContactResultEnum,
    SsoNotFound: 'SSONotFound' as AuthenticateContactResultEnum,
    RedirectNeeded: 'RedirectNeeded' as AuthenticateContactResultEnum
};

