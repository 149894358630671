/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PendingReservationLineView } from './pendingReservationLineView';


export interface PendingReservationSummaryLine { 
    PassePartoutName?: string | null;
    EventDate?: Date | null;
    EventName?: string | null;
    EventId?: number | null;
    PassePartoutId?: number | null;
    CrossSellProductVariantName?: string | null;
    CrossSellProductVariantId?: number | null;
    CrossSellProductName?: string | null;
    TicketLines?: Array<PendingReservationLineView> | null;
}

