/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PassePartout } from './passePartout';
import { PaymentMethodModel } from './paymentMethodModel';
import { AccountingDimension } from './accountingDimension';
import { PassePartoutDeliveryMethod } from './passePartoutDeliveryMethod';
import { PassePartoutPriceList } from './passePartoutPriceList';


export interface PassePartoutStoreArgs { 
    LinkedPriceLists?: Array<PassePartoutPriceList> | null;
    PassePartout?: PassePartout;
    PaymentMethods?: Array<PaymentMethodModel> | null;
    DeliveryMethods?: Array<PassePartoutDeliveryMethod> | null;
    AccountingDimension?: AccountingDimension;
}

