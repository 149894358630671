/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CustomerView { 
    SalesGroupId?: number;
    Id?: number;
    ContactId?: number;
    CustomerType?: number;
    Name?: string | null;
    TelephoneNr?: string | null;
    ExternalId?: string | null;
    CustomerNumber?: string | null;
    Addressline1?: string | null;
    Street?: string | null;
    StreetNr?: string | null;
    PostalCode?: string | null;
    City?: string | null;
    CountryIso3?: string | null;
    Country?: string | null;
    DateOfBirth?: Date | null;
    Email?: string | null;
    FirstName?: string | null;
    MiddleName?: string | null;
    LastName?: string | null;
    Initials?: string | null;
    SSOId?: string | null;
    ExternalTransactionId?: string | null;
    IsIdentified?: boolean;
    TotalCredit?: number;
    readonly Age?: number | null;
    readonly CalculatedName?: string | null;
}

