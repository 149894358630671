/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PeriodEnum } from './periodEnum';
import { IntermediateCancellationTypeEnum } from './intermediateCancellationTypeEnum';
import { StartDateTypeEnum } from './startDateTypeEnum';


export interface MembershipModel { 
    Id?: number;
    Code?: string | null;
    Name?: string | null;
    PriceCategoryId?: number;
    OnSale?: boolean;
    OnSaleFrom?: Date | null;
    OnSaleTill?: Date | null;
    ShopTabName?: string | null;
    AutomaticRenewal?: boolean;
    IntermediateCancellationType?: IntermediateCancellationTypeEnum;
    IntermediateCancellationPeriod?: PeriodEnum;
    IntermediateCancellationPeriodNumber?: number | null;
    DurationPeriod?: PeriodEnum;
    DurationPeriodNumber?: number | null;
    StartDateType?: StartDateTypeEnum;
    TagId?: number | null;
    Reminder?: boolean;
    ReminderDaysBeforeExpiry?: number | null;
    ReminderMailTemplateId?: number | null;
    MediumTypeId?: number | null;
    SystemManaged?: boolean;
    IdentificationRequired?: boolean;
    HardcardTemplateId?: number | null;
    RenewableFromAmount?: number;
    RenewableFromPeriod?: PeriodEnum;
    ReportToC3V?: boolean;
    DefaultSalesGroupId?: number | null;
    WelcomeMailTemplateId?: number | null;
    IsRecurring?: boolean;
    RecurringRenewFromDate?: Date | null;
    RenewableInTicketShop?: boolean;
    MembershipTypeId?: number | null;
}

