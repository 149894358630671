/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface EventView { 
    Id?: number;
    Name?: string | null;
    StartDate?: Date;
    StartTime?: string;
    EndDate?: Date;
    EndTime?: string;
    Active?: boolean;
    StartDateTime?: Date;
    EndDateTime?: Date;
    EventTypeName?: string | null;
    OnSaleInBizzTix?: boolean;
    AvailableInDistributix?: boolean;
    EventPlacementAvailable?: boolean;
    SyncWithACL?: boolean;
    EventTypeId?: number;
    DoorOpen?: Date;
    ImageUrlEventType?: string | null;
    ImageUrlHomeTeam?: string | null;
    ImageUrlAwayTeam?: string | null;
    DoorOpenFormatted?: string | null;
    VisibleInFanmanagement?: boolean;
    FantastixManageableFrom?: Date | null;
    FantastixManageableTill?: Date | null;
    FantastixOnSale?: boolean;
    FantastixOnSaleFrom?: Date | null;
    FantastixOnSaleTill?: Date | null;
    Named?: boolean;
}

