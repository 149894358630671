/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProlongationStateEnum } from './prolongationStateEnum';


export interface ProlongationView { 
    Id?: number;
    CreatedByUserId?: number;
    AcceptedByUserId?: number | null;
    AcceptedDate?: Date | null;
    State?: ProlongationStateEnum;
    Created?: Date;
    Modified?: Date | null;
    CreatedByFirstName?: string | null;
    CreatedByMiddleName?: string | null;
    CreatedByLastName?: string | null;
    AcceptedByFirstName?: string | null;
    AcceptedByMiddleName?: string | null;
    AcceptedByLastName?: string | null;
    SeasonName?: string | null;
    LastUpdated?: Date | null;
}

