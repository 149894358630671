/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PendingOrderLineView } from './pendingOrderLineView';


export interface PendingOrderSummaryLine { 
    PassePartoutName?: string | null;
    EventDate?: Date | null;
    EventName?: string | null;
    EventId?: number | null;
    PassePartoutId?: number | null;
    MembershipName?: string | null;
    MembershipId?: number | null;
    CrossSellProductVariantName?: string | null;
    CrossSellProductVariantId?: number | null;
    CrossSellProductName?: string | null;
    MatchDayPassId?: number | null;
    MatchDayPassName?: string | null;
    TicketLines?: Array<PendingOrderLineView> | null;
}

