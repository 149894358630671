/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ActionType } from './actionType';
import { ButtonStyle } from './buttonStyle';


export interface ShopButtonState { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    TranslationCode?: string | null;
    ButtonStyle?: ButtonStyle;
    ActionType?: ActionType;
    Action?: string | null;
    ActiveFrom?: Date | null;
    ActiveTill?: Date | null;
    OpenInNewTab?: boolean | null;
    InitiativeId?: number | null;
}

