/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MediaView } from './mediaView';


export interface MediaViewPagedResult { 
    Data?: Array<MediaView> | null;
    TotalPages?: number;
    TotalRecords?: number;
    PageNumber?: number;
    PageSize?: number;
}

