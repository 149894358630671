/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VATRateModel } from './vATRateModel';
import { PriceVariationModel } from './priceVariationModel';
import { DiscountPriceGroupModel } from './discountPriceGroupModel';
import { PriceCategoryModel } from './priceCategoryModel';


export interface PriceGroupEditDetailsModel { 
    VatRate?: VATRateModel;
    PriceVariations?: Array<PriceVariationModel> | null;
    PriceCategories?: Array<PriceCategoryModel> | null;
    Prices?: Array<DiscountPriceGroupModel> | null;
}

