/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketStatusModel } from './ticketStatusModel';


export interface TicketForCustomerWithStatus { 
    OrderLineId?: number;
    OrderId?: number;
    DeliveryMethodId?: number;
    DeliveryMethodCode?: string | null;
    EventPlacementId?: number;
    Barcode?: string | null;
    OrderType?: number;
    OrderDate?: Date;
    TicketCustomerName?: string | null;
    TicketCustomerId?: number;
    TicketCustomerIsIdentified?: boolean;
    OrderCustomerName?: string | null;
    OrderCustomerId?: number;
    OrderCustomerNumber?: string | null;
    OrderCustomerIsIdentified?: boolean;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    SectionName?: string | null;
    SectionShortName?: string | null;
    ParentSectionName?: string | null;
    ParentSectionShortName?: string | null;
    EventName?: string | null;
    EventId?: number;
    EventStartDate?: Date;
    EventEndDate?: Date;
    DoorOpenBusiness?: Date;
    NameHomeTeam?: string | null;
    NameAwayTeam?: string | null;
    EventTypeId?: number | null;
    ShopDelivery?: number;
    PriceVariationId?: number | null;
    PriceTotal?: number | null;
    PriceExVat?: number | null;
    SalesChannelId?: number;
    EventTypeName?: string | null;
    EventTypeLogoId?: number | null;
    PriceVariant?: string | null;
    PassePartoutId?: number | null;
    PassePartoutName?: string | null;
    AvailableForSecondary?: boolean;
    EpSecondaryOrderId?: number | null;
    TicketStatus?: TicketStatusModel;
}

