/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerMembershipStatusEnum } from './customerMembershipStatusEnum';
import { PeriodEnum } from './periodEnum';
import { IntermediateCancellationTypeEnum } from './intermediateCancellationTypeEnum';


export interface CustomerMembershipModel { 
    Id?: number;
    CustomerId?: number;
    MembershipId?: number;
    MembershipName?: string | null;
    OwnerCustomerName?: string | null;
    CommunicationEmail?: string | null;
    StartDate?: Date;
    EndDate?: Date;
    Status?: CustomerMembershipStatusEnum;
    IntermediateCancellationType?: IntermediateCancellationTypeEnum;
    IntermediateCancellationPeriod?: PeriodEnum;
    IntermediateCancellationPeriodNumber?: number | null;
    StatusDescription?: string | null;
    FirstAvailCancelDate?: Date | null;
    LastPrintDate?: Date | null;
    Barcode?: string | null;
    IsRecurring?: boolean;
    OrderId?: number;
    OrderedBy?: string | null;
    MayNotBeRenewed?: boolean | null;
    RenewableInTicketShop?: boolean | null;
}

