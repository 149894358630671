/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PendingLineArgs } from './pendingLineArgs';


export interface PutPendingOrderLinesArgs { 
    ParentVenueBuildingBlockId?: number | null;
    GroupingId?: string | null;
    OrderLines?: Array<PendingLineArgs> | null;
    EventIds?: Array<number> | null;
    PassePartoutIds?: Array<number> | null;
    MembershipIds?: Array<number> | null;
    CrossSellProductVariantIds?: Array<number> | null;
    MatchDayPassId?: number | null;
}

