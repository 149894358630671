/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketSwapStatusEnum } from './ticketSwapStatusEnum';
import { EventPlacementPrintTrailView } from './eventPlacementPrintTrailView';
import { IdentificationCustomer } from './identificationCustomer';


export interface PrintableTicket { 
    Id?: number;
    EventStartDate?: Date;
    EventName?: string | null;
    EventId?: number;
    TicketCustomerName: string | null;
    IdentificationCustomerModel: IdentificationCustomer;
    Ticketname?: string | null;
    Row?: string | null;
    Seat?: string | null;
    LastPrintEticket?: EventPlacementPrintTrailView;
    LastPrintSoftcard?: EventPlacementPrintTrailView;
    LastDuplication?: EventPlacementPrintTrailView;
    LastBarcodeViewed?: EventPlacementPrintTrailView;
    Barcode?: string | null;
    Selectable?: boolean;
    TicketSwapStatus?: TicketSwapStatusEnum;
}

