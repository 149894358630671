import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NotificationCommunicationService } from '@tymes4-shared';
import {TranslateService} from '@ngx-translate/core'
import { HttpLoaderService } from '@tymes4-shared';
import { ConstService } from '../../services/const.service';
import { GenerateReportArgs, ReportService, SeasonService } from '../../api';
import { SnackbarHelper } from '../../helpers/snackbar-helper';
import { ApplicationModuleHelperService, ApplicationModules } from '../../services/application-module.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-generate-accounting-report-template-form',
  templateUrl: './generate-accounting-report-template-form.component.html'
})
export class GenerateAccountingReportTemplateForm implements OnInit {

  public form: FormGroup;
  public selectableSeasons = null;
  private editingObject = null;
  public isAvailableRecurringMemberships: boolean;

  public isInArchiveMode = this.authService.isInArchiveMode();

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    public dialogRef:  MatDialogRef<GenerateAccountingReportTemplateForm>,
    private loader: HttpLoaderService,
    public constants: ConstService,
    private seasonService: SeasonService,
    private reportService: ReportService,
    public translate: TranslateService,
    private notificationCommunicationService: NotificationCommunicationService,
    private applicationModuleHelperService: ApplicationModuleHelperService,
    private snackbar: SnackbarHelper,
    private authService: AuthService) { }


  ngOnInit() {
    this.loadSettings();
    this.editingObject = null;

    this.editingObject = {};

    this.createForm();
    this.retrieveRequiredData();

    this.form.patchValue(this.editingObject);
  }

  loadSettings() {
    this.applicationModuleHelperService.getActiveModules().subscribe((module) => {
      this.isAvailableRecurringMemberships = (module && module.includes(ApplicationModules.RecurringMemberships));;
    });
  }

  createForm() {

    this.form = new FormGroup({
      SeasonId: new FormControl(null),
    });

    this.updateFormValidators();

  }

  updateFormValidators() {
    this.setConditionalValidation('SEASON', 'SeasonId', [Validators.required]);
    this.form.updateValueAndValidity();
  }

  setConditionalValidation(paramName, formControlName, validators, update = true) {
    this.form.get(formControlName).setValidators(validators);
  }

  retrieveRequiredData() {
    this.loader.open();

    this.seasonService.getAllSeasons().subscribe((seasons) => {
      this.selectableSeasons = seasons;

      this.loader.close();
    })
  }

  formIsInvalid() {
    return (this.form.invalid);
  }


  download(fileType) {
    this.loader.open();

    const args : GenerateReportArgs = this.form.value;
    args.ReportId = 52;
    args.FileType = fileType;

    this.reportService.generateAccountingReport(args).subscribe((lro: any) => {
      this.loader.close();
      if (lro) {
        this.snackbar.open('GENERIC.SNACKBAR.REPORT-GENERATION-STARTED');
        this.notificationCommunicationService.updateNotificationsAndLROS();
        this.dialogRef.close(true);
      }
    });
  }
}
