/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ModificationActionEnum } from './modificationActionEnum';
import { OrderLine } from './orderLine';
import { PendingOrderLine } from './pendingOrderLine';


export interface OrderLineAction { 
    RegenerateBarcode?: boolean;
    ModificationAction?: ModificationActionEnum;
    PendingOrderLine?: PendingOrderLine;
    BluePrintOrderLine?: OrderLine;
    IsCreditInvoiceRequired?: boolean;
}

