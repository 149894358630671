/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DirectDebitPaymentMethod } from './directDebitPaymentMethod';


export interface PlaceResult { 
    Handled?: boolean;
    Id?: number;
    UnavailableLineIds?: Array<number> | null;
    OrderId?: number;
    PaymentRequired?: boolean;
    UpfrontPaymentAllowed?: boolean;
    DirectDebitAllowed?: boolean;
    PaymentByInvoiceAllowed?: boolean;
    RecurrentPayment?: boolean;
    DirectDebitPaymentMethods?: Array<DirectDebitPaymentMethod> | null;
    MaxCollectionDate?: Date;
    OrderDenied?: boolean;
    UserDenyReason?: string | null;
    IDEALIN3Payment?: boolean;
    PaymentReference?: string | null;
}

