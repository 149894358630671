/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreditChangeAction } from './creditChangeAction';


export interface CustomerCreditChangeRevisionView { 
    Id?: number;
    Created?: Date;
    CustomerId?: number;
    CreditChange?: number;
    CreditChangeAction?: CreditChangeAction;
    UserCalculatedName?: string | null;
    Reason?: string | null;
    OrderLineId?: number | null;
    RevisedCustomerId?: number | null;
    EventPlacementId?: number | null;
    CustomerPassePartoutId?: number | null;
    EntranceId?: number | null;
    PriceVariationId?: number | null;
    AppliedCredit?: number | null;
    ManualPrice?: number | null;
    NewBarcode?: boolean | null;
    Comment?: string | null;
}

