/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MatchDayPassPriceVatComponentView } from './matchDayPassPriceVatComponentView';


export interface MatchDayPassPriceModel { 
    Id?: number;
    MatchDayPassId?: number;
    PriceVariationId?: number;
    SectorId?: number;
    PriceVariationName?: string | null;
    Price?: number;
    PriceTotal?: number;
    Components?: Array<MatchDayPassPriceVatComponentView> | null;
}

