/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IncrementalStatus } from './incrementalStatus';


export interface SecondaryTransactionIncrementalBase { 
    Created?: Date;
    Modified?: Date | null;
    Deleted?: Date | null;
    CreatedUtc?: Date;
    ModifiedUtc?: Date | null;
    DeletedUtc?: Date | null;
    OrderLineId?: number;
    Status?: IncrementalStatus;
    OrderId?: number;
    Type?: string | null;
    Barcode?: string | null;
    ProductId?: string | null;
    EventId?: number | null;
    EventName?: string | null;
    EventDateTime?: Date | null;
    EventDateTimeUtc?: Date | null;
    CustomerPassePartoutId?: number | null;
    PassePartoutId?: number | null;
    PassePartoutName?: string | null;
    CustomerId?: number;
    TicketCustomerId?: number;
    OrderDate?: Date;
    OrderDateUtc?: Date;
    OrderLinePrice?: number;
    OriginalPrice?: number;
    PaymentMethodId?: number;
    PaymentMethodName?: string | null;
    SalesChannelId?: number;
    SalesChannelName?: string | null;
    BlockId?: number;
    ParentSections?: Array<string> | null;
    Stand?: string | null;
    Block?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    PriceVariationId?: number;
    PriceVariationName?: string | null;
    PriceVariationIsDefault?: boolean;
    TotalWODiscount?: number;
    TotalDiscount?: number;
    Total?: number;
    OrderType?: number;
    MatchDayPassId?: number | null;
    MatchDayPassName?: string | null;
    IsPaidWithMDP?: boolean;
    OriginalOrderId?: number;
    OriginalOrderLineId?: number;
    OriginalOrderDate?: Date;
    OriginalOrderDateUtc?: Date;
    OriginalCustomerId?: number;
    OriginalPaymentMethodId?: number;
    OriginalPaymentMethodName?: string | null;
    MarketplacePrice?: number;
    MarketplaceIBAN?: string | null;
}

