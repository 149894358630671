/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PendingOrderStateEnum } from './pendingOrderStateEnum';


export interface PendingOrder { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    UserId?: number | null;
    CustomerId?: number | null;
    ModificationForOrderId?: number | null;
    Reference?: string | null;
    HandlesProlongation?: boolean;
    State?: PendingOrderStateEnum;
    UID?: string;
    DeliveryMethodId?: number | null;
    SalesPeriodId?: number | null;
    SalesChannelId?: number;
    HandlesReservation?: boolean;
    InitiativeId?: number | null;
    QueueToken?: string | null;
}

