/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { NameValuePair } from './nameValuePair';
import { Entrance } from './entrance';
import { VenueBuildingBlock } from './venueBuildingBlock';
import { VenueBuildingBlockChangeResultView } from './venueBuildingBlockChangeResultView';


export interface VenueBuildingBlockDetail { 
    Id?: number;
    Name?: string | null;
    Version?: string | null;
    OrientationDegrees?: number | null;
    Scale?: number | null;
    Created?: Date | null;
    Modified?: Date | null;
    VenueBuildingBlockTypeId?: number;
    Parents?: Array<VenueBuildingBlock> | null;
    Entrances?: Array<Entrance> | null;
    ChangeResults?: Array<VenueBuildingBlockChangeResultView> | null;
    TicketCountPerType?: Array<NameValuePair> | null;
    HasPlacements?: boolean;
    HasUnpublishedChanges?: boolean;
}

