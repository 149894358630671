/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiscountGenericTypeEnum } from './discountGenericTypeEnum';
import { DiscountTypeEnum } from './discountTypeEnum';
import { DiscountGeneralEnum } from './discountGeneralEnum';
import { DiscountProductTypeEnum } from './discountProductTypeEnum';
import { DiscountCodeTypeEnum } from './discountCodeTypeEnum';


export interface Discount { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    Name?: string | null;
    Active?: boolean;
    ValidFrom?: Date | null;
    ValidTill?: Date | null;
    GenericCode?: boolean;
    GenericPrefix?: string | null;
    GenericLength?: number;
    GenericType?: DiscountGenericTypeEnum;
    CodeType?: DiscountCodeTypeEnum;
    EstimatedTotalCount?: number | null;
    DiscountProductType?: DiscountProductTypeEnum;
    GeneralDiscount?: DiscountGeneralEnum;
    NonStackable?: boolean;
    MaximumCount?: number | null;
    MaximumForOrder?: number | null;
    AmountPerc?: number;
    DiscountType?: DiscountTypeEnum;
    ShopDisplayName?: string | null;
    RestValueRemains?: boolean;
    RestrictToChannel?: boolean;
}

