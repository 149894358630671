/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductTypeEnum } from './productTypeEnum';


export interface PendingOrderLineView { 
    Id?: number;
    Price?: number;
    Total?: number;
    VAT?: number;
    TicketTypeId?: number;
    TicketTypeName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    TicketCustomer?: string | null;
    TicketCustomerId?: number;
    TicketCustomerType?: number;
    TicketCustomerFirstName?: string | null;
    TicketCustomerMiddleName?: string | null;
    TicketCustomerLastName?: string | null;
    TicketCustomerInitials?: string | null;
    readonly TicketCustomerCalculatedName?: string | null;
    TicketCustomerIsIdentified?: boolean;
    TicketCustomerNumber?: string | null;
    EventName?: string | null;
    EventId?: number | null;
    EventDate?: Date | null;
    ParentSectionName?: string | null;
    VATRateId?: number;
    VATRateDescription?: string | null;
    PassePartoutId?: number | null;
    PassePartoutName?: string | null;
    SelectedSingleEntranceId?: number | null;
    SelectedSingleEntranceName?: string | null;
    TicketText?: string | null;
    MembershipId?: number | null;
    MembershipName?: string | null;
    DiscountId?: number | null;
    DiscountDisplayName?: string | null;
    DiscountCodeId?: number | null;
    TotalWoDiscount?: number;
    MatchDayPassId?: number | null;
    MatchDayPassName?: string | null;
    MatchDayPassMaxTickets?: number | null;
    ParentSectionShortName?: string | null;
    PendingOrderId?: number;
    VATRatePercentage?: number;
    ParentVenueBuildingBlockId?: number | null;
    GroupingId?: string | null;
    Row?: number | null;
    Column?: number | null;
    EventPlacementId?: number | null;
    ParentSectionColor?: string | null;
    PriceVariationName?: string | null;
    Named?: boolean;
    IsPlaced?: boolean;
    DisplayOrder?: number;
    OriginalPrice?: number;
    SeasonName?: string | null;
    SubscriptionTypeId?: number | null;
    TicketTextId?: number | null;
    AssigningRatio?: number | null;
    PriceVariationId?: number | null;
    ProductType?: ProductTypeEnum;
    CrossSellProductId?: number | null;
    CrossSellProductName?: string | null;
    CrossSellProductVariantId?: number | null;
    CrossSellProductVariantName?: string | null;
    ContingentId?: number | null;
    ContingentName?: string | null;
    SectorName?: string | null;
    SectorId?: string | null;
    SelectedMatchDayPassId?: number | null;
    MembershipIsRecurring?: boolean | null;
    CommunicationEmail?: string | null;
}

