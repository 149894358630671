/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { MobileTicketingEvent } from './mobileTicketingEvent';
import { TagModelSlim } from './tagModelSlim';
import { MobileTicketStatusModel } from './mobileTicketStatusModel';
import { MobileTicketDeliveryMethod } from './mobileTicketDeliveryMethod';
import { EventBarcodeData } from './eventBarcodeData';


export interface IBAPassePartoutTicket { 
    CustomerNumber?: string | null;
    OrderCustomerName?: string | null;
    OrderCustomerNumber?: string | null;
    TicketCustomerName?: string | null;
    TicketCustomerNumber?: string | null;
    Barcode?: string | null;
    Entrance?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    PriceExVat?: number | null;
    PriceTotal?: number | null;
    PriceVariant?: string | null;
    TicketFrontBase64?: string | null;
    TicketBackBase64?: string | null;
    VenueName?: string | null;
    VenueShortName?: string | null;
    SectionName?: string | null;
    SectionShortName?: string | null;
    ParentSectionName?: string | null;
    ParentSectionShortName?: string | null;
    TicketType?: string | null;
    OrderId?: number;
    OrderLineId?: number;
    DeliveryMethod?: MobileTicketDeliveryMethod;
    ExternalTransactionId?: string | null;
    IndexNumber?: string | null;
    PassePartoutId?: number;
    PassePartoutName?: string | null;
    SeasonId?: number;
    SeasonName?: string | null;
    Category?: string | null;
    HardCardVersion?: number | null;
    Events?: Array<MobileTicketingEvent> | null;
    CustomerTags?: Array<TagModelSlim> | null;
    CustomerPhoto?: string | null;
    EventBarcodes?: { [key: string]: EventBarcodeData; } | null;
    AdditionalAttribute?: string | null;
    CustomerPassePartoutId?: number;
    PositionStatuses?: { [key: string]: MobileTicketStatusModel; } | null;
}

