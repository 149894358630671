/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { EventPlacementTrailDetailView } from './eventPlacementTrailDetailView';


export interface EventPlacementDetailsView { 
    EventPlacementId?: number;
    ActiveBarcode?: string | null;
    EventName?: string | null;
    ParentName?: string | null;
    ParentShortName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    TicketTypeName?: string | null;
    ActiveOrderId?: number | null;
    OrderDate?: Date | null;
    CustomerName?: string | null;
    PassePartoutName?: string | null;
    TrailItems?: Array<EventPlacementTrailDetailView> | null;
}

