/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VerificationsPerType } from './verificationsPerType';


export interface IdVerificationStatisticsModel { 
    StartDateTime?: Date;
    EndDateTime?: Date;
    Uploads?: number;
    IDIN?: number;
    FastId?: number;
    FixedCosts?: number;
    VariableCosts?: number;
    VerificationsPerType?: Array<VerificationsPerType> | null;
}

