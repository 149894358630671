/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderLinePrice } from './orderLinePrice';
import { OrderLineSubscription } from './orderLineSubscription';


export interface PlacePendingOrderArgs { 
    PendingOrderId?: number;
    OrderLinePrices?: Array<OrderLinePrice> | null;
    OrderLineSubscriptions?: Array<OrderLineSubscription> | null;
    Reference?: string | null;
    SendOrderConfirmationMail?: boolean;
    SubscriptionProlongation?: boolean;
    DeliveryMethodId?: number | null;
    PayByInvoice?: boolean;
    AccountManagerId?: number | null;
    DoFullSupport?: boolean;
    QueueToken?: string | null;
    SalesChannelId?: number | null;
    DisableC3V?: boolean;
    IncludeInInvoiceBatch?: boolean;
    CreateCreditInvoice?: boolean;
    RecurringMembershipImport?: boolean;
}

