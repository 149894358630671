/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Customer } from './customer';
import { PendingOrder } from './pendingOrder';
import { SalesEntityModificationDetailLine } from './salesEntityModificationDetailLine';
import { PendingReservation } from './pendingReservation';


export interface SalesEntityModificationDetail { 
    ReservationId?: number | null;
    OrderId?: number | null;
    Lines?: Array<SalesEntityModificationDetailLine> | null;
    Customer?: Customer;
    PendingReservation?: PendingReservation;
    PendingOrder?: PendingOrder;
}

