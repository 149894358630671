/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProductTypeEnum } from './productTypeEnum';
import { CalculatedPriceVariantModel } from './calculatedPriceVariantModel';
import { CrossSellProductVariantModel } from './crossSellProductVariantModel';


export interface SalesEntityModificationDetailLine { 
    ModificationAction?: number;
    OrderLineId?: number | null;
    ReservationLineId?: number | null;
    PendingOrderLineId?: number | null;
    PendingReservationLineId?: number | null;
    ParentVenueBuildingBlockId?: number | null;
    GroupingId?: string | null;
    Id?: number | null;
    Price?: number;
    Total?: number;
    VAT?: number;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    Row?: number | null;
    Column?: number | null;
    TicketCustomer?: string | null;
    TicketCustomerCalculatedName?: string | null;
    TicketCustomerId?: number;
    EventName?: string | null;
    EventId?: number | null;
    EventDate?: Date | null;
    ParentSectionName?: string | null;
    VATRateId?: number;
    VATRateDescription?: string | null;
    PassePartoutId?: number | null;
    PassePartoutName?: string | null;
    PriceVariationName?: string | null;
    SubscriptionTypeId?: number | null;
    Barcode?: string | null;
    PriceVariants?: Array<CalculatedPriceVariantModel> | null;
    SelectedVariant?: CalculatedPriceVariantModel;
    TicketTypeId?: number;
    RequireNewBarcode?: boolean;
    SelectedSingleEntranceId?: number | null;
    SelectedSingleEntranceName?: string | null;
    TicketTextId?: number | null;
    TicketText?: string | null;
    TicketCustomerIsBlacklisted?: boolean | null;
    ProductType?: ProductTypeEnum;
    MembershipId?: number | null;
    MembershipName?: string | null;
    DiscountDisplayName?: string | null;
    DiscountCodeId?: number | null;
    CrossSellProductId?: number | null;
    CrossSellProductName?: string | null;
    CrossSellProductVariantId?: number | null;
    CrossSellProductVariantName?: string | null;
    CrossSellVariants?: Array<CrossSellProductVariantModel> | null;
    SelectedCrossSellVariant?: CrossSellProductVariantModel;
    MatchDayPassName?: string | null;
    SectorName?: string | null;
    PurchasedWithMDPName?: string | null;
}

