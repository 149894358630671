<!-- MODAL HEADER-->
<div class="row modal-bar">
  <div class="col-sm-6">
    <h5 class="modal-title">{{ passedData.title }}</h5>
  </div>
  <div class="col-sm-6">
    <div class="modal-actions">
      <div class="modal-close">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span (click)="dialogRef.close(false);">×</span>
        </button>
      </div>
    </div>
  </div>
</div>


<form [formGroup]="form" class="form-content">

  <div class="section-wrapper">
    <div class="form-layout">
      <div class="row mg-b-25">
        <div class="col-4">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.NAME' | translate}}
              <span class="tx-danger">*</span></label>
            <input type="text" class="form-control" name="Name" formControlName="Name" placeholder="{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.PLACEHOLDER.NAME' | translate}}">
            <t4-display-validation controlName="Name" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
        <div class="col-4 ">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.CODE' | translate}}
              <span class="tx-danger">*</span></label>
            <input type="text" class="form-control" name="Code" formControlName="Code" placeholder="{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.PLACEHOLDER.CODE' | translate}}">
            <t4-display-validation controlName="Code" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.TRANSLATION-CODE' | translate}}
              <span class="tx-danger">*</span></label>
            <input type="text" class="form-control" name="TranslationCode" formControlName="TranslationCode" placeholder="{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.PLACEHOLDER.TRANSLATION-CODE' | translate}}">
            <t4-display-validation controlName="TranslationCode" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
      </div>
      <div class="row mg-b-25">
        <div class="col-4">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.AVAILABLE-FORM-HOURS-BEFORE-EVENT' | translate}}</label>
            <input type="number" min="0" class="form-control" name="AvailableFromHoursBeforeEvent" formControlName="AvailableFromHoursBeforeEvent" placeholder=" - " >
            <t4-display-validation controlName="AvailableFromHoursBeforeEvent" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.AVAILABLE-UNTIL-HOURS-BEFORE-EVENT' | translate}}</label>
            <input type="number" min="0" class="form-control" name="AvailableUntilHoursBeforeEvent" formControlName="AvailableUntilHoursBeforeEvent" placeholder=" - " >
            <t4-display-validation controlName="AvailableUntilHoursBeforeEvent" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="ckbox ckbox-input">
              <input type="checkbox" name="DefaultEnabled" formControlName="DefaultEnabled" ><span class="ml-1">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.DEFAULT-ENABLED' | translate}}</span>
            </label>
            <label class="ckbox ckbox-input">
              <input type="checkbox" name="CreateHardCard" formControlName="CreateHardCard" ><span class="ml-1">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.CREATE-HARDCARD' | translate}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="row mg-b-25">
        <div class="col-4">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.PRICE' | translate}}
              <span class="tx-danger">*</span></label>
            <input type="number" min="0" step="0.01" class="form-control" name="Price" formControlName="Price" placeholder="{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.PLACEHOLDER.PRICE' | translate}}">
            <t4-display-validation controlName="Price" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.DEFAULT-PRICING-METHOD-ID' | translate}}
              <span class="tx-danger">*</span></label>
            <ng-select [items]="pricingMethods" bindLabel="text" bindValue="id" formControlName="DefaultPricingMethodId" placeholder="{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.PLACEHOLDER.DEFAULT-PRICING-METHOD-ID' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>

            <t4-display-validation controlName="DefaultPricingMethodId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.PRICE-COMPONENT-ID' | translate}}
              <span class="tx-danger">*</span></label>
            <ng-select [items]="pricecomponents" bindLabel="Name" bindValue="Id" formControlName="PriceComponentId" placeholder="{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.PLACEHOLDER.PRICE-COMPONENT-ID' | translate}}"
                       [clearable]="false" notFoundText="{{'GENERIC.EDITDIALOG.PLACEHOLDER.NOITEMSFOUND' | translate}}" appendTo="body"></ng-select>

            <t4-display-validation controlName="PriceComponentId" [form]="form" [messages]="validationMessages"></t4-display-validation>
          </div>
        </div>
      </div>
      <div class="row mg-b-25">
        <div class="col-12">
          <div class="form-group">
              <label class="form-control-label">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.INSTRUCTION' | translate}}</label>
              <input type="text" class="form-control" name="Instruction" formControlName="Instruction">
          </div>
        </div>
      </div>
      <div class="row mg-b-25">
        <div class="col-6">
          <div class="form-group">
            <label class="ckbox ckbox-input">
              <input type="checkbox" name="ShowInShop" formControlName="ShowInShop" ><span class="ml-1">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.SHOW-IN-SHOP' | translate}}</span>
            </label>
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label class="ckbox ckbox-input">
              <input type="checkbox" name="ShowExternal" formControlName="ShowExternal" ><span class="ml-1">{{'DIALOGS.EDIT-DELIVERY-METHODS.FORM.LABEL.SHOW-EXTERNAL' | translate}}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="form-layout-footer row">
        <div class="col-6">
          <button (click)="submit()" [disabled]="form.invalid" class="btn btn-block btn-primary bd-0"><i
            class="far fa-save"></i><span>{{ 'GENERIC.BUTTON.SAVE' | translate }}</span></button>
        </div>
        <div class="col-6">
          <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i
            class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
        </div>

      </div><!-- form-layout-footer -->
    </div><!-- form-layout -->
  </div>

</form>
