/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerPhotoStatusEnum } from './customerPhotoStatusEnum';


export interface CustomerPhoto { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    Status?: CustomerPhotoStatusEnum;
    IndexNumber?: number | null;
    CustomerId?: number;
    LastStatusDate?: Date;
    BatchId?: number | null;
    ImageName?: string | null;
    ImportedFileName?: string | null;
    UserId?: number | null;
}

