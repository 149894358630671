import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Validators, FormGroup, FormControl } from '@angular/forms';
import { ConstService } from 'app/shared/services/const.service';
import {FormHelper} from '../../helpers/form.helper';
import {PricingMethods} from '../../enums/pricing-methods.enum';
import { DeliveryMethodsValidator } from './deliverymethods.validator';
import { TranslateService } from '@ngx-translate/core';
import { PriceComponent, PriceComponentService } from '../../api';

@Component({
  selector: 'app-edit-delivery-methods-form',
  templateUrl: './edit-delivery-methods-form.component.html'
})
export class EditDeliveryMethodsFormComponent implements OnInit {

  public form: FormGroup;
  public pricecomponents: Array<PriceComponent> = [];

  public isSystemDeliveryMethod: boolean = false;
  public isShopDelivery: boolean = false;


  public pricingMethods = [
    {id: PricingMethods.PER_ORDER, text: this.translate.instant('DIALOGS.EDIT-DELIVERY-METHODS.FORM.DROPDOWN.PER-ORDER')},
    {id: PricingMethods.PER_LINE, text: this.translate.instant('DIALOGS.EDIT-DELIVERY-METHODS.FORM.DROPDOWN.PER-ORDER-LINE')},
    {id: PricingMethods.PER_CUSTOMER, text: this.translate.instant('DIALOGS.EDIT-DELIVERY-METHODS.FORM.DROPDOWN.PER-TICKET-RELATION')},
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
              public dialogRef: MatDialogRef<EditDeliveryMethodsFormComponent>,
              public priceComponentService: PriceComponentService,
              private deliveryMethodsValidator: DeliveryMethodsValidator,
              public constants: ConstService,
              private translate: TranslateService) {
  }

  validationMessages = [];
  ngOnInit() {
      this.isSystemDeliveryMethod = (this.passedData.payload && this.passedData.payload !== null ? this.passedData.payload.IsSystemMethod : false);
      this.isShopDelivery = (this.passedData.payload && this.passedData.payload !== null ? this.passedData.payload.Code == 'E-TICKET' : false);

      this.form = new FormGroup({
        Id: new FormControl(0), // to preserve value
        Name: new FormControl({ value: '', disabled: this.isSystemDeliveryMethod }, [Validators.required]),
        Code: new FormControl({ value: '', disabled: this.isSystemDeliveryMethod }, { validators: [Validators.required], asyncValidators: [this.deliveryMethodsValidator.deliverMethodCodeTaken.bind(this)], updateOn: "blur" }),
        TranslationCode: new FormControl('', [Validators.required]),
        DefaultPricingMethodId: new FormControl(null, [Validators.required]),
        IsPrintDefault: new FormControl({ value: false, disabled: this.isSystemDeliveryMethod }, []),
        AvailableUntilHoursBeforeEvent: new FormControl({ value: null, disabled: this.isSystemDeliveryMethod }, []),
        AvailableFromHoursBeforeEvent: new FormControl({ value: null, disabled: this.isSystemDeliveryMethod }, []),
        DefaultEnabled: new FormControl({ value: false, disabled: this.isSystemDeliveryMethod }, []),
        CreateHardCard: new FormControl({ value: false, disabled: this.isSystemDeliveryMethod }, []),
        Price: new FormControl(null, [Validators.required]),
        PriceComponentId: new FormControl(null, [Validators.required]),
        Instruction: new FormControl(''),
        ShowInShop: new FormControl({ value: false, disabled: this.isShopDelivery }, []),
        ShowExternal: new FormControl({ value: false, disabled: this.isShopDelivery }, [])
      });


      this.priceComponentService.getManagedPriceComponents('DMS').subscribe((data: Array<PriceComponent>) => {
        this.pricecomponents = data;
      });

      if (this.passedData.payload) {
        this.deliveryMethodsValidator.editingObject = this.passedData.payload;
        this.form.patchValue(this.passedData.payload);
      }

  }

  submit() {
    const value = this.form.getRawValue();
    value.IsSystemMethod = this.isSystemDeliveryMethod;
    value.IsPrintDefault = !!value.IsPrintDefault;
    value.DefaultEnabled = !!value.DefaultEnabled;
    value.CreateHardCard = !!value.CreateHardCard;
    value.ShowInShop = !!value.ShowInShop;
    value.ShowExternal = !!value.ShowExternal;
    this.dialogRef.close(value);
  }
}
