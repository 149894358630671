/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MarketplaceOrderViewWithIsInvoiced { 
    SalesGroupId?: number;
    Id?: number;
    TotalExVat?: number;
    TotalInclVat?: number;
    Vat?: number;
    OrderType?: number;
    OrderProducts?: string | null;
    CustomerId?: number;
    OrderDate?: Date;
    Reference?: string | null;
    CustomerName?: string | null;
    CustomerNumber?: string | null;
    CustomerExternalId?: string | null;
    ContactEmail?: string | null;
    CustomerType?: number;
    Balance?: number;
    Payed?: boolean;
    SalesChannelName?: string | null;
    SalesChannelId?: number | null;
    PendingCancelByCustomer?: boolean;
    Summary?: string | null;
    FirstName?: string | null;
    MiddleName?: string | null;
    LastName?: string | null;
    Initials?: string | null;
    readonly CalculatedName?: string | null;
    IsIdentified?: boolean;
    Created?: Date;
    Modified?: Date | null;
    Deleted?: Date | null;
    CanceledByCustomer?: boolean | null;
    PayByInvoice?: boolean;
    InitiativeId?: number | null;
    IsInvoiced?: boolean;
}

