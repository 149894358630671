/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { Instalment } from './instalment';
import { PaymentMethodAvailableToEnum } from './paymentMethodAvailableToEnum';


export interface PaymentMethodDBModel { 
    Id?: number;
    Name?: string | null;
    TranslationCode?: string | null;
    Active?: boolean;
    Code?: string | null;
    UpfrontPayment?: boolean;
    DirectDebit?: boolean;
    AllowedInPayPerMail?: boolean;
    Instalments?: number | null;
    AvailableTo?: PaymentMethodAvailableToEnum;
    SystemManaged?: boolean;
    Surcharge?: number | null;
    PriceComponentId?: number;
    InvoicingAllowed?: boolean;
    AllowInCreditManagement?: boolean;
    LinkedInstalments?: Array<Instalment> | null;
    SuspenseAccount?: string | null;
}

