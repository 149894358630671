/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface SingleMergeSummaryView { 
    BlacklistItems?: number;
    Contacts?: number;
    Memberships?: number;
    Passepartouts?: number;
    Photos?: number;
    CustomerTags?: number;
    EmailSettings?: number;
    Invoices?: number;
    MailTemplates?: number;
    Payments?: number;
    PaymentMethodExclusions?: number;
    Subscriptions?: number;
    Tickets?: number;
    Orders?: number;
    OrderLines?: number;
    OrdersBlocked?: number;
    OrderFailures?: number;
    OrderImportLines?: number;
    PendingOrders?: number;
    PendingOrderLines?: number;
    PendingReservations?: number;
    PendingReservationLines?: number;
    OutstandingVouchers?: number;
    PaymentRequests?: number;
    PrintJobItems?: number;
    SalesPeriodRepurchaseLines?: number;
    EventPlacements?: number;
    CustomerMemos?: number;
    IdentificationDocuments?: number;
}

