/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketSwapStatusEnum } from './ticketSwapStatusEnum';


export interface PassePartoutPositionView { 
    EventPlacementId?: number;
    Barcode?: string | null;
    ParentShortName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    EventId?: number;
    EventName?: string | null;
    EventStartDateTime?: Date;
    Deleted?: boolean;
    DeletionReason?: string | null;
    DeletedByUserFullname?: string | null;
    CustomerPassePartoutId?: number;
    Deletable?: boolean;
    LatestTicketSwapStatus?: TicketSwapStatusEnum;
    TicketSwapStatusTranslationCode?: string | null;
    CanRevokeInBackoffice?: boolean | null;
}

