/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { VenueBuildingBlockType } from './venueBuildingBlockType';


export interface VenueBuildingBlock { 
    Id?: number;
    Created?: Date | null;
    Modified?: Date | null;
    Deleted?: Date | null;
    RowVersion?: string | null;
    VenueBuildingBlockTypeId?: number;
    BuildingBlockType?: VenueBuildingBlockType;
    SectionNr?: string | null;
    Name?: string | null;
    ShortName?: string | null;
    Version?: string | null;
    ParentId?: number | null;
    PriceCategoryId?: number | null;
    PlacementTypeId?: number | null;
    TicketTypeId?: number | null;
    ImageId?: number | null;
    Row?: number | null;
    Column?: number | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    OrientationDegrees?: number | null;
    Scale?: number | null;
    SeatRestrictionId?: number | null;
    TicketNamingType?: number | null;
    SaleCategoryId?: number | null;
    ContingentId?: number | null;
    StadiumPlanSectionId?: number | null;
    HexColorCode?: string | null;
    HasUnpublishedChanges?: boolean;
}

