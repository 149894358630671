/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketsForCustomerMDPView } from './ticketsForCustomerMDPView';


export interface MDPsForCustomerView { 
    CustomerMatchDayPassId?: number;
    Name?: string | null;
    PointsUsed?: number;
    MaxPoints?: number;
    SectorName?: string | null;
    OrderId?: number;
    OrderCustomerName?: string | null;
    Tickets?: Array<TicketsForCustomerMDPView> | null;
}

