/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { PrintTrailTypeEnum } from './printTrailTypeEnum';


export interface EventPlacementPrintTrailView { 
    EventPlacementId?: number;
    Created?: Date;
    By?: string | null;
    CustomerNumber?: string | null;
    IsDuplicate?: boolean;
    DuplicationReason?: string | null;
    PrintTrailType?: PrintTrailTypeEnum;
}

