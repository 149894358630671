/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerMembershipStatusEnum } from './customerMembershipStatusEnum';


export interface SalesChannelCustomerView { 
    SalesChannelId?: number;
    CustomerId?: number;
    CustomerName?: string | null;
    CustomerNumber?: string | null;
    MembershipId?: number | null;
    MembershipName?: string | null;
    MembershipCode?: string | null;
    MembershipFrom?: Date | null;
    MembershipTill?: Date | null;
    MembershipStatus?: CustomerMembershipStatusEnum;
}

