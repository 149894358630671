/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SeatPlanPlacementRow } from './seatPlanPlacementRow';
import { SeatRestriction } from './seatRestriction';
import { CalculatedPriceVariantModel } from './calculatedPriceVariantModel';
import { SellablePlacementSectionView } from './sellablePlacementSectionView';
import { PriceCategory } from './priceCategory';
import { Contingent } from './contingent';


export interface SeatPlanPlacementView { 
    Rows?: Array<SeatPlanPlacementRow> | null;
    Details?: SellablePlacementSectionView;
    CategoryPrices?: Array<CalculatedPriceVariantModel> | null;
    AvailableContingents?: Array<Contingent> | null;
    AvailableSeatRestrictions?: Array<SeatRestriction> | null;
    AvailablePriceCategories?: Array<PriceCategory> | null;
    LowestMarketplacePrice?: number | null;
    HighestMarketplacePrice?: number | null;
}

