import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { CalculatedPriceVariantModel, OrderDetailsService, OrderLinePriceComponentView, OrderLineViewWithPricing } from "../../../api";
import { FormControl, FormGroup } from "@angular/forms";
import { EditPriceFormComponent } from "../../../dialogs/edit-price-form/edit-price-form.component";
import { DialogHelper, DialogWidth } from "@tymes4-shared";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: 'app-order-line-price-dialog',
    templateUrl: './order-line-price-dialog.component.html',
    styleUrls: ['./order-line-price-dialog.component.scss']
})
export class OrderLinePriceDialogComponent implements OnInit {
    public orderLine: OrderLineViewWithPricing;
    public customerCredit: number;
    public selectedVariant: CalculatedPriceVariantModel = {};
    public changedPrice: {
        selectedVariant?: CalculatedPriceVariantModel,
        appliedCredit?: number,
        manualPriceChange?: OrderLinePriceComponentView[]
    } = {selectedVariant: {}};
    public form: FormGroup;
    public valid: boolean = true;

    constructor(
        @Inject(MAT_DIALOG_DATA) public passedData: any,
        public dialogRef:  MatDialogRef<OrderLinePriceDialogComponent>,
        private dialog: MatDialog,
        private translate: TranslateService,
        private orderDetailsService: OrderDetailsService
    ) {}

    get changedTotal() {
        if (this.changedPrice.manualPriceChange) {
            return this.changedPrice.manualPriceChange.reduce((prev, curr) => prev + curr.AmountInVAT, 0);
        }
        if (this.changedPrice.selectedVariant && this.changedPrice.selectedVariant.VariantId != this.orderLine.SelectedVariant?.VariantId) {
            return this.changedPrice.selectedVariant.PriceTotal;
        }
        return null;
    }

    ngOnInit(): void {
        this.customerCredit = this.passedData.customer.TotalCredit;
        this.orderLine = this.passedData.orderLine;
        this.form = new FormGroup({
            selectedVariant: new FormControl(this.orderLine.SelectedVariant),
            appliedCredit: new FormControl(0),
        });
    }

    onSelectedPriceVariantChange(e: CalculatedPriceVariantModel) {
        this.selectedVariant = e;
        this.changedPrice.selectedVariant = e;
    }

    updateCredit(e: number) {
        if (e >= 0 && e <= this.customerCredit) {
            this.changedPrice.appliedCredit = e;
            this.valid = true;
        }
        else
            this.valid = false;
    }

    save() {
        this.dialogRef.close(this.changedPrice)
    }
    
    openDialog() {
        this.orderDetailsService.getPriceComponent(this.orderLine.Id).subscribe(data => {
            const options = DialogHelper.GetDialogOptions(DialogWidth.md, {
                title: this.translate.instant('SALES-ENTITY-MODIFICATION.DIALOG.TITLE.EDIT-PRICE-STRUCTURE'),
                readOnly: false,
                priceLineComponentSelections: data
            });
            const dialogRef: MatDialogRef<any> = this.dialog.open(EditPriceFormComponent, options);
    
            dialogRef.afterClosed().subscribe(lines => {
                if (lines) {
                    this.changedPrice.manualPriceChange = lines;
                }
            });
        });
    }
}
