/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ProlongationLineStateEnum } from './prolongationLineStateEnum';


export interface ProlongationLineDetailView { 
    Id?: number;
    ProlongationId?: number;
    State?: ProlongationLineStateEnum;
    Included?: boolean;
    Postponed?: boolean;
    ModifiedByUserId?: number | null;
    Modified?: Date | null;
    Price?: number;
    VAT?: number;
    Total?: number;
    PriceVariationName?: string | null;
    OrderCustomer?: string | null;
    TicketCustomer?: string | null;
    ParentSectionName?: string | null;
    TicketTypeName?: string | null;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    OriginPassePartoutName?: string | null;
    OriginPassePartoutId?: number;
    TargetPassePartoutName?: string | null;
    TargetPassePartoutId?: number;
    ModifiedByUserFirstName?: string | null;
    ModifiedByUserMiddleName?: string | null;
    ModifiedByUserLastName?: string | null;
    ReadOnly?: boolean;
    ProlongationDeleted?: Date | null;
    ProlongationLineDeleted?: Date | null;
    OrderId?: number;
    OrderLineId?: number;
    SeasonsLeftToProlong?: number;
    SubscriptionStartSeasonId?: number;
    SubscriptionTypeSeasons?: number;
    SubscriptionLineActiveSeasonId?: number;
    FollowingSeasonId?: number;
    SubscriptionId?: number;
}

