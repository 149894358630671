/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { IDValuePair } from './iDValuePair';


export interface SellablePlacementSectionView { 
    EventPlacementId?: number | null;
    EventId?: number;
    Name?: string | null;
    ShortName?: string | null;
    FullPath?: string | null;
    GroupingId?: string | null;
    VenueBuildingBlockId?: number | null;
    TicketsPerTicketTypeId?: Array<IDValuePair> | null;
    HasTicketsAvailable?: boolean;
    HasMarketplaceTicketsAvailable?: boolean;
    HasPriceVariations?: boolean;
    SectionSVG?: string | null;
    HexColorCode?: string | null;
    SaleCategoryId?: number | null;
    SectionNumber?: string | null;
}

