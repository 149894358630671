/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface IdentificationCustomer { 
    FirstName?: string | null;
    MiddleName?: string | null;
    LastName?: string | null;
    Initials?: string | null;
    readonly CalculatedName?: string | null;
    CustomerType?: number;
    CustomerName?: string | null;
    IsIdentified?: boolean;
    CustomerNumber?: string | null;
    TicketCustomerId?: number;
}

