/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { TicketSwapStatusEnum } from './ticketSwapStatusEnum';
import { IdentificationStateEnum } from './identificationStateEnum';


export interface TicketHistoryCurrentView { 
    Created?: Date;
    Status?: TicketSwapStatusEnum;
    EventPlacementId?: number;
    RowNumber?: string | null;
    SeatNumber?: string | null;
    SectionName?: string | null;
    TicketTypeId?: number | null;
    TicketTypeName?: string | null;
    EventId?: number;
    EventName?: string | null;
    EventStartDate?: Date;
    EventEndDate?: Date;
    HomeTeamName?: string | null;
    AwayTeamName?: string | null;
    EventTypeName?: string | null;
    OrderId?: number;
    OrderType?: number;
    OrderCustomerId?: number;
    BoughtById?: number;
    BoughtByName?: string | null;
    BoughtByNumber?: string | null;
    BoughtByIsIdentified?: boolean;
    BoughtByIdentificationStatus?: IdentificationStateEnum;
    TicketHolderId?: number;
    TicketHolderName?: string | null;
    TicketHolderNumber?: string | null;
    TicketHolderIsIdentified?: boolean;
    TicketHolderIdentificationStatus?: IdentificationStateEnum;
    Barcode?: string | null;
    SecondaryBoughtById?: number;
    SecondaryBoughtByName?: string | null;
    SecondaryBoughtByNumber?: string | null;
    SecondaryBoughtByIsIdentified?: boolean;
    SecondaryBoughtByIdentificationStatus?: IdentificationStateEnum;
}

