/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SalesChannelView } from './salesChannelView';
import { Language } from './language';
import { MenuItemView } from './menuItemView';


export interface UserDetailsView { 
    Id?: number;
    Email?: string | null;
    TFAEnabled?: boolean;
    Username?: string | null;
    Firstname?: string | null;
    Middlename?: string | null;
    Lastname?: string | null;
    readonly Fullname?: string | null;
    Roles?: Array<string> | null;
    MenuItems?: Array<MenuItemView> | null;
    Channels?: Array<SalesChannelView> | null;
    IsArchiveMode?: boolean;
    ProfileImage?: string | null;
    Active?: boolean;
    Description?: string | null;
    LastLoginDate?: Date | null;
    LanguageCode?: string | null;
    Language?: Language;
    ShowGoToProfile?: boolean;
    SystemSettings?: { [key: string]: string; } | null;
    PrimarySalesChannelId?: number;
}

