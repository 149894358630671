
<form [formGroup]="form" class="form-content">
    <div class="section-wrapper">
      <div class="form-layout">
  
        <div class="row mg-b-25">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="form-control-label">{{'CUSTOMER.REVOKE.REASON' | translate}}</label>
              <textarea class="form-control" name="Reason" formControlName="Reason"></textarea>
            </div>
          </div>
        </div>
  
        <div class="form-layout-footer row">
          <div class="col-6">
            <button (click)="saveRevoke()" [disabled]="form.invalid" class="btn btn-block btn-primary bd-0"><i class="far fa-save"></i><span>{{'GENERIC.BUTTON.SAVE' | translate }}</span></button>
          </div>
          <div class="col-6">
            <button (click)="dialogRef.close(false);" class="btn btn-block btn-secondary bd-0"><i class="fas fa-ban"></i><span>{{ 'GENERIC.BUTTON.CANCEL' | translate }}</span></button>
          </div>
        </div>
  
      </div>
    </div>
  </form>