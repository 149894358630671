/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CalculatedPriceVariantModel } from './calculatedPriceVariantModel';
import { SubscriptionTypeModel } from './subscriptionTypeModel';
import { SubscriptionView } from './subscriptionView';
import { PendingOrderSummary } from './pendingOrderSummary';
import { PendingOrderDiscountTotals } from './pendingOrderDiscountTotals';
import { PendingOrderLineViewWithPricing } from './pendingOrderLineViewWithPricing';


export interface PendingOrderView { 
    Id?: number;
    CustomerName?: string | null;
    CustomerId?: number;
    Reference?: string | null;
    HandlesProlongation?: boolean;
    SalesPeriodId?: number | null;
    OrderLines?: Array<PendingOrderLineViewWithPricing> | null;
    Subscriptions?: Array<SubscriptionView> | null;
    SubscriptionTypes?: Array<SubscriptionTypeModel> | null;
    Summary?: PendingOrderSummary;
    ModificationForOrderId?: number | null;
    HandlesReservation?: boolean;
    InitiativeGuid?: string | null;
    DeliveryMethodId?: number | null;
    DeliveryMethodInstruction?: string | null;
    FullSupportOptionEnabled?: boolean;
    SalesChannelId?: number;
    SalesGroup?: number;
    DiscountTotals?: Array<PendingOrderDiscountTotals> | null;
    AllPriceVariants?: Array<CalculatedPriceVariantModel> | null;
}

