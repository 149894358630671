import {HttpResponse} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmService, HttpLoaderService, KeyValuePair, SnackbarService, T4Validators} from '@tymes4-shared';
//FDP: Tymes4V2 migration: ngx-custom-validators is incompatible with ng13, this is a fork
//import {CustomValidators} from 'ngx-custom-validators';
import { CustomValidators } from '@narik/custom-validators';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, tap} from 'rxjs/operators';
import {ValidationHelper} from '../../helpers/validation.helper';
import {ResellerValidator} from '../../validators/reseller-validator';
import {SnackbarHelper} from './../../../shared/helpers/snackbar-helper';
import { AddressModel, Contact, ContactModelPagedResult, ContactService, Country, CountryService, FantastixService, PostalCodeService, ResellerDetailsView, ResellerRole, ResellerService } from '../../api';


@Component({
    selector: 'app-edit-fanclub-form',
    templateUrl: './edit-fanclub-form.component.html',
    styleUrls: ['./edit-fanclub-form.component.scss']
})
export class EditFanclubFormComponent implements OnInit {

    public availableRoles: Array<ResellerRole> = null;
    resellerId = 0;
    mainForm: FormGroup;
    public resellerDetails: any = {};
    countries: any = null;
    public selectedTab = 'First';

    isLoadingReseller = false;
    isNewReseller = false;

    public saving = false;
    public isDirty = false;

    private checkingPostalCode = false;
    private postalCheckOK = true;

    private addressChecked = false;
    private foundAddress: AddressModel;

    public resellerContact: any = {};
    isLoadingContact = false;
    public isNewResellerContact = false;

    contactsLoading = false;
    contacts: Observable<unknown>;
    contactsInput$ = new Subject<string>();

    validationMessages = [];

    constructor(@Inject(MAT_DIALOG_DATA) public passedData: any,
            private validationHelper: ValidationHelper,
            private countryService: CountryService,
            private translateService: TranslateService,
            private contactService: ContactService,
            private resellerService: ResellerService,
            private postalcodeService: PostalCodeService,
            private fantastixService: FantastixService,
            private loader: HttpLoaderService,
            public dialogRef: MatDialogRef<EditFanclubFormComponent>,
            private snackBar: SnackbarService,
            private snackbarHelper: SnackbarHelper,
            private resellerValidator: ResellerValidator,
            private confirmService: ConfirmService) { }

    private requiredFields: KeyValuePair<boolean> = null;

    isRequiredField(field: string, targetForm: FormGroup = null): boolean {
        if (this.requiredFields === null) {
          this.requiredFields = {};
        }

        if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {
          const form = targetForm ? targetForm : this.mainForm;
          const formField = form.get(field);
          if (!formField.validator) {
            return false;
          }

          const validator = formField.validator({} as AbstractControl);
          this.requiredFields[field] = validator && validator.required;

          formField.validator(formField);
        }
        return this.requiredFields[field];
      }

    ngOnInit() {

        const contactCtrl = new FormControl('', [Validators.required]);
        this.mainForm = new FormGroup({
            ResellerId: new FormControl(0),
            SalesChannelName: new FormControl('', [Validators.required, Validators.maxLength(512)]),
            SalesChannelCode: new FormControl('', [Validators.required], this.resellerValidator.salesChannelCodeTaken.bind(this)),
            ResellerWebsite: new FormControl('', [CustomValidators.url]),
            ResellerEmail: new FormControl('', [Validators.required, CustomValidators.email]),
            ResellerIBAN: new FormControl('', [Validators.required, T4Validators.validateIban]),
            AllowNewMembers: new FormControl(false),
            LocationName: new FormControl('', [Validators.required]),
            LocationStreet: new FormControl('', [Validators.required]),
            LocationStreetNr: new FormControl('', [Validators.required, CustomValidators.number]),
            LocationStreetNrAddition: new FormControl(''),
            LocationPostalCode: new FormControl('', [Validators.required]),
            LocationCountryId: new FormControl('', [Validators.required]),
            LocationCity: new FormControl('', [Validators.required]),
            SecondaryLocationName: new FormControl(''),
            SecondaryLocationStreet: new FormControl(''),
            SecondaryLocationStreetNr: new FormControl(null, [CustomValidators.number]),
            SecondaryLocationStreetNrAddition: new FormControl(''),
            SecondaryLocationPostalCode: new FormControl(''),
            SecondaryLocationCountryId: new FormControl(null),
            SecondaryLocationCity: new FormControl(''),
            PrimaryContactId: contactCtrl
        });

      this.isNewResellerContact = this.passedData.isNew;
      if (!this.isNewResellerContact) {
        this.resellerContact = this.passedData.resellerContact;
      } else {
        this.resellerContact = {};
        this.resellerContact.Roles = [];
      }

        this.isNewReseller = this.passedData.isNew;
        if (!this.isNewReseller) {
            this.resellerDetails = this.passedData.fanclub;
            //this.mainForm.controls.SalesChannelCode.disable();
        } else {
            this.resellerDetails = {};
            this.resellerDetails.AllowNewMembers = true;
            this.resellerDetails.LocationCountryId = null;
            this.resellerDetails.SecondaryLocationCountryId = null;
            
        }
        this.resellerService.getResellerRoles().subscribe((data: Array<ResellerRole>) => {
          this.availableRoles = data;
        });

        this.countryService.listAllCountries().subscribe((countries: Array<Country>) => {
            // Get translated strings
            countries.map(c => c.Name = this.translateService.instant(`COUNTRY.${c.Iso2}`));

            // Sort by prio and alphabetically
            this.countries = countries.sort((c1, c2) => {
            const prio1 = c1.Priority || 0;
            const prio2 = c2.Priority || 0;
            if (prio2 - prio1 !== 0) {
                return (prio2 - prio1);
            }

            // Remove non-letters from the names
            const name1 = c1.Name.replace(/[^a-z]/gi, '');
            const name2 = c2.Name.replace(/[^a-z]/gi, '');
            return (name1 < name2) ? -1 : (name1 > name2) ? 1 : 0;
            });
        });



        if (this.resellerDetails != null && this.resellerDetails.PrimaryContactId) {
            this.loader.open();
            // we need to get the customer to preset the automcomplete
            this.contactService.getContact(this.resellerDetails.PrimaryContactId).subscribe((contact: Contact) => {
              this.loader.close();
              contactCtrl.setValue(contact.Id.toString());
              this.loadContacts(contact);
              this.resellerDetails.PrimaryContactId = contact.Id;
            });
           } else {
             this.loadContacts();
           }

        this.resellerValidator.editingObject = this.resellerDetails;
        this.mainForm.patchValue(this.resellerDetails);
    }

    private loadContacts(contact: any = null) {
        const prefilled = contact ? [contact] : [];
        this.contacts = concat(
            of(prefilled), // default items
            this.contactsInput$.pipe(
                debounceTime(500),
                skipWhile(x => x.length < 3),
                distinctUntilChanged(),
                tap(() => { this.contactsLoading = true; }),
                switchMap(term => this.contactService.searchContact(term, 1, 100).pipe(
                    //catchError(() => of([])), // empty list on error
                    map((f: ContactModelPagedResult) => f.Records),
                    tap(() => { this.contactsLoading = false; })
                ))
            )
        );
      }

    onSelectedTabChange(e) {
        this.selectedTab = e.selectedTab.id;
    }  
    checkPostalCode(forceCheck: boolean) {
        const postalcode = this.mainForm['controls'].LocationPostalCode;
        const streetNr = this.mainForm['controls'].LocationStreetNr;
        const doCall = ((postalcode.value && streetNr.value) && (postalcode.valid && streetNr.valid) && (postalcode.dirty || streetNr.dirty));

        if (doCall || forceCheck) {

          this.checkingPostalCode = true;

          this.postalcodeService.checkPostalCode(postalcode.value, streetNr.value).subscribe((address: AddressModel) => {
            this.postalCheckOK = (address !== null);
            this.addressChecked = true;
            this.foundAddress = address;

            if (this.postalCheckOK) {
                this.mainForm.patchValue({LocationCity: address.City, LocationCountryId: address.CountryId, LocationPostalCode: address.PostalCode, LocationStreet: address.Street, LocationStreetNr: address.StreetNr, LocationStreetNrAddition: address.StreetNrAddition});
            }
            this.checkingPostalCode = false;
          });
        }
      }

      checkSecondaryPostalCode(forceCheck: boolean) {
        var postalcode = this.mainForm['controls'].SecondaryLocationPostalCode;
        var streetNr = this.mainForm['controls'].SecondaryLocationStreetNr;
        var doCall = ((postalcode.value && streetNr.value) && (postalcode.valid && streetNr.valid) && (postalcode.dirty || streetNr.dirty));

        if (doCall || forceCheck) {

          this.checkingPostalCode = true;

          this.postalcodeService.checkPostalCode(postalcode.value, streetNr.value).subscribe((address: AddressModel) => {
            this.postalCheckOK = (address !== null);
            this.addressChecked = true;
            this.foundAddress = address;

            if (this.postalCheckOK) {
                this.mainForm.patchValue({SecondaryLocationCity: address.City, SecondaryLocationCountryId: address.CountryId, SecondaryLocationPostalCode: address.PostalCode, SecondaryLocationStreet: address.Street, SecondaryLocationStreetNr: address.StreetNr, SecondaryLocationStreetNrAddition: address.StreetNrAddition});
            }
            this.checkingPostalCode = false;
          });
        }
      }

      fanclubIsValidAndDirty() {
        return this.mainForm.valid && this.mainForm.dirty;
      }
      UpdateData(form_data: any) {
        if (!this.isNewReseller) {

          this.loader.open();
          this.saving = true;

          this.fantastixService.updateFanclub(form_data).subscribe((data: string) => {
            this.loader.close();

            this.handleSubmitResult(data);
          });
        } else {
          this.fantastixService.createFanclub(form_data).subscribe((data: string) => {
            this.loader.close();

            this.handleSubmitResult(data);
          });
        }
      }

      submit() {
        const form_data = this.mainForm.value;

        if (this.resellerDetails.PrimaryContactId === form_data.PrimaryContactId) {
          this.UpdateData(form_data);
        } else {
          const fanAdminName = 'fan-admin';
          this.resellerService.getResellerExistingRoles(form_data.PrimaryContactId).subscribe((roles: Array<ResellerRole>) => {
            let bIsFanAdmin = false;
            if (roles != null) {
              roles.forEach(role => {
                if (role.Name === fanAdminName) {
                  bIsFanAdmin = true;
                }
              });
            }

            if (bIsFanAdmin) {
              // Contact already is a fanmanager, ask confirmation before add
              this.confirmService.confirm({
                title: this.translateService.instant('DIALOGS.EDIT-FANCLUB.FORM.CONFIRM.TITLE.EXISTING-MANAGER'),
                message: this.translateService.instant('DIALOGS.EDIT-FANCLUB.FORM.CONFIRM.MESSAGE.EXISTING-MANAGER'),

                okonly: false
              }).subscribe((confirmed: boolean) => {
                if (confirmed) {
                  this.UpdateData(form_data);
                }
              });
            } else {
              this.UpdateData(form_data);
            }
          });
        }
      }

      handleSubmitResult(data: any) {

        if (data && data.length > 0) {

          const message = this.translateService.instant('DIALOGS.EDIT-FANCLUB.FORM.CONFIRM.MESSAGE.CANNOT-SAVE-FANCLUB') + data;
          this.confirmService.confirm({
            okonly: true,
            title: this.translateService.instant('DIALOGS.EDIT-FANCLUB.FORM.CONFIRM.TITLE.CANNOT-SAVE-FANCLUB'),
            message: message
          }).subscribe((ok) => {});
        } else {

          this.dialogRef.close(true);
          this.saving = false;
          this.snackBar.open(this.translateService.instant('GENERIC.SNACKBAR.FANCLUB-SAVED'), 'GENERIC.SNACKBAR.CLOSE');
        }

      }

      updateRequiredFields() {
       
        const secondaryLocationNameControl = this.mainForm.get('SecondaryLocationName');
        const secondaryLocationStreetControl = this.mainForm.get('SecondaryLocationStreet');
        const secondaryLocationStreetNrControl = this.mainForm.get('SecondaryLocationStreetNr');
        const secondaryLocationStreetNrAdditionControl = this.mainForm.get('SecondaryLocationStreetNrAddition');
        const secondaryLocationPostalCodeControl = this.mainForm.get('SecondaryLocationPostalCode');
        const secondaryLocationCountryIdControl = this.mainForm.get('SecondaryLocationCountryId');
        const secondaryLocationCityControl = this.mainForm.get('SecondaryLocationCity');

        if ((secondaryLocationNameControl.value != null && secondaryLocationNameControl.value != '' )||
            (secondaryLocationStreetControl.value != null && secondaryLocationStreetControl.value != '' ) ||
            (secondaryLocationStreetNrControl.value != null && secondaryLocationStreetNrControl.value != '' ) ||
            (secondaryLocationStreetNrAdditionControl.value != null && secondaryLocationStreetNrAdditionControl.value != '' )|| 
            (secondaryLocationPostalCodeControl.value != null && secondaryLocationPostalCodeControl.value != '' )||
            (secondaryLocationCountryIdControl.value != null && secondaryLocationCountryIdControl.value != 0 )|| 
            (secondaryLocationCityControl.value != null && secondaryLocationCityControl.value != '' ) ) {
          //LocationName required
          secondaryLocationNameControl.setValidators([Validators.required]);
          secondaryLocationStreetControl.setValidators([Validators.required]);
          secondaryLocationStreetNrControl.setValidators([Validators.required]);
          secondaryLocationPostalCodeControl.setValidators([Validators.required]);
          secondaryLocationCountryIdControl.setValidators([Validators.required]);
          secondaryLocationCityControl.setValidators([Validators.required]);
          
        }
        else {
          //LocationName not required
          secondaryLocationNameControl.setValidators(null);
          secondaryLocationStreetControl.setValidators(null);
          secondaryLocationStreetNrControl.setValidators(null);
          secondaryLocationPostalCodeControl.setValidators(null);
          secondaryLocationCountryIdControl.setValidators(null);
          secondaryLocationCityControl.setValidators(null);
          this.requiredFields = null;
          
        }
        secondaryLocationNameControl.updateValueAndValidity();
        secondaryLocationStreetControl.updateValueAndValidity();
        secondaryLocationStreetNrControl.updateValueAndValidity();
        secondaryLocationPostalCodeControl.updateValueAndValidity();
        secondaryLocationCountryIdControl.updateValueAndValidity();
        secondaryLocationCityControl.updateValueAndValidity();
        
      }

      actionClick(action: string) {
        if (action === 'delete') {
          this.openConfirmDialog();
        }
      }

      openConfirmDialog() {
        const salesChannelName = this.passedData.fanclub.SalesChannelName ;
        const resellerId = this.passedData.fanclub.ResellerId ;

        this.confirmService.confirm({
          title: this.translateService.instant('GENERIC.CONFIRM.WARNING.TITLE.ARE-YOU-SURE'),
          message: this.translateService.instant('DIALOGS.EDIT-FANCLUB.FORM.CONFIRM.MESSAGE.DELETE-FANCLUB', {salesChannelName: salesChannelName}),
          okonly: false
        }).subscribe((confirmed: boolean) => {
          this.loader.open();
          if (confirmed === true) {
            this.fantastixService.deleteSecondaryRoomFanclub(resellerId).subscribe((data: string) => {
              this.loader.close();

              if (data && data.length > 0) {
              
              const message = this.translateService.instant('DIALOGS.EDIT-FANCLUB.FORM.CONFIRM.MESSAGE.CANNOT-DELETE-FANCLUB') + data;
                this.confirmService.confirm({
                  okonly: true,
                  title: this.translateService.instant('DIALOGS.EDIT-FANCLUB.FORM.CONFIRM.TITLE.CANNOT-DELETE-FANCLUB'),
                  message: message
                }).subscribe((ok) => {});
              } else {
              
                // refresh after delete
                this.snackbarHelper.deleted();
                this.loadResellerDetails() ;
              }
            });
          } else {
            this.loader.close();
          }
        });
      }

      loadResellerDetails() {
        this.resellerService.getResellerDetails(this.passedData.fanclub.ResellerId).subscribe((data: ResellerDetailsView) => {
          
          // set the data on screen
          this.passedData.fanclub = data;
          this.ngOnInit();
        });
    }     

}
