/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CustomerIdentificationTypeEnum } from './customerIdentificationTypeEnum';
import { IdentificationStateEnum } from './identificationStateEnum';


export interface IdVerificationModel { 
    Id?: number;
    CustomerId?: number;
    UserId?: number;
    UserFirstName?: string | null;
    Reason?: string | null;
    Status?: IdentificationStateEnum;
    CustomerIdentificationType?: CustomerIdentificationTypeEnum;
    IdinTransactionId?: string | null;
    IdinEntranceCode?: string | null;
    Created?: Date;
    CustomerName?: string | null;
    Fullname?: string | null;
    CustomerFirstName?: string | null;
    CustomerMiddleName?: string | null;
    CustomerLastName?: string | null;
    CustomerInitials?: string | null;
    readonly CustomerCalculatedName?: string | null;
    CustomerIsIdentified?: boolean;
    CustomerNumber?: string | null;
    UserMiddleName?: string | null;
    UserLastName?: string | null;
    UserInitials?: string | null;
    readonly UserCalculatedName?: string | null;
}

