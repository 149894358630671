/**
 * Tymes4 Backoffice API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 2.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { DiscountGeneralEnum } from './discountGeneralEnum';
import { DiscountProductTypeEnum } from './discountProductTypeEnum';


export interface DiscountProductRestrictionModel { 
    GeneralDiscount?: DiscountGeneralEnum;
    DiscountProductType?: DiscountProductTypeEnum;
    Id?: number;
    EventIds?: Array<number> | null;
    EventTypeIds?: Array<number> | null;
    PassePartoutIds?: Array<number> | null;
    InitiativeIds?: Array<number> | null;
}

