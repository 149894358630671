import {HttpResponse} from '@angular/common/http';
import {Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {HttpLoaderService, KeyValuePair, SnackbarService, ConfirmService} from '@tymes4-shared';
import {concat, Observable, of, Subject} from 'rxjs';
import {catchError, debounceTime, distinctUntilChanged, map, skipWhile, switchMap, tap} from 'rxjs/operators';
import { isUnionTypeNode } from 'typescript';
import { TranslateService } from '@ngx-translate/core';
import { Contact, ContactModelPagedResult, ContactService, ResellerRole, ResellerService } from '../../api';

@Component({
  selector: 'app-edit-reseller-contact',
  templateUrl: './edit-reseller-contact.component.html',
})

export class EditResellerContactComponent implements OnInit {

  public availableRoles: Array<ResellerRole> = null;
  public form: FormGroup;
  public saving = false;

  resellerId: number;
  public resellerContact: any = {};
  isLoadingContact: boolean = false;
  public isNewResellerContact: boolean = false;

  contactsLoading = false;
  contacts: Observable<unknown>;
  contactsInput$ = new Subject<string>();

  validationMessages = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public passedData: any,
    private loader: HttpLoaderService,
    public dialogRef: MatDialogRef<EditResellerContactComponent>,
    private resellerService: ResellerService,
    private contactService: ContactService,
    private snackBar: SnackbarService,
    private confirmService: ConfirmService,
    private translate: TranslateService
  ) { }

  private requiredFields: KeyValuePair<boolean> = null;

  isRequiredField(field: string, targetForm: FormGroup = null): boolean {
      if (this.requiredFields === null)
        this.requiredFields = {};

      if (this.requiredFields[field] === null || this.requiredFields[field] === undefined) {
        const form = targetForm ? targetForm : this.form;
        const formField = form.get(field);
        if (!formField.validator) {
          return false;
        }

        const validator = formField.validator({} as AbstractControl);
        this.requiredFields[field] = validator && validator.required;

        formField.validator(formField);
      }
      return this.requiredFields[field];
    }


  ngOnInit() {
    // first: new FormControl({value: 'Nancy', disabled: true}, Validators.required),


    this.resellerId = this.passedData.resellerId;
    this.isNewResellerContact = this.passedData.isNew;
    if (!this.isNewResellerContact) {
        this.resellerContact = this.passedData.resellerContact;
    }
    else {
        this.resellerContact = {};
        this.resellerContact.Roles = [];
    }

    this.resellerService.getResellerRoles().subscribe((data: Array<ResellerRole>) => {
      this.availableRoles = data;
    });

    var contactCtrl = new FormControl({value: '', disabled: !this.isNewResellerContact}, [Validators.required]);
    this.form = new FormGroup({
      ContactId: contactCtrl,
      ActiveRoles: new FormControl('', Validators.required)
    });

    if (this.resellerContact != null && this.resellerContact.ContactId) {
      this.loader.open();
      // we need to get the customer to preset the automcomplete
      this.contactService.getContact(this.resellerContact.ContactId).subscribe((contact: Contact) => {
        this.loader.close();
        contactCtrl.setValue(contact.Id.toString());
        this.loadContacts(contact);
        this.resellerContact.ContactId = contact.Id;
      });
      } else {
        this.loadContacts();
      }

     this.form.patchValue(this.resellerContact);
  }

  private getItemDisplayText(item): string {
    
    if (!item || item === null || item.Name === null) return '';

    let name = item.FirstName + ' ';
    if (item.MiddleName) name += item.MiddleName + ' ';
    name += item.LastName;

    let email = item.Email ? item.Email : '-';

    return `${name} (${item.CustomerNumber} / ${email})`;

  }
  private loadContacts(contact: any = null) {
    const prefilled = contact ? [contact] : [];
    this.contacts = concat(
        of(prefilled), // default items
        this.contactsInput$.pipe(
            debounceTime(500),
            skipWhile(x => x.length < 3),
            distinctUntilChanged(),
            tap(() => { this.contactsLoading = true; }),
            switchMap(term => this.contactService.searchContact(term, 1, 100).pipe(
                catchError(() => of([])), // empty list on error
                map((f: ContactModelPagedResult) => f.Records),
                tap(() => { this.contactsLoading = false; })
            ))
        )
    );
  }

  roleIsChecked(r) {
    return this.resellerContact && this.resellerContact.Roles.includes(r.Name);
  }

  checkChanged(e, data) {
    if (e.target.checked) {
      this.resellerContact.Roles.push(data.Name);
    } else {
      const index = this.resellerContact.Roles.indexOf(data.Name, 0);
      if (index > -1) {
        this.resellerContact.Roles.splice(index, 1);
      }
    }
  }

  IsValidAndDirty() {
    return this.form.valid && this.form.dirty;
  }

  submit() {
    if (this.isNewResellerContact)
    {
      const data = this.form.value;

      // Check whether the contact being added isn't a fanmanager yet
      const fanAdminName = 'fan-admin';
      if (this.resellerContact.Roles.length > 0 && this.resellerContact.Roles.includes(fanAdminName))
      {
        this.resellerService.getResellerExistingRoles(data.ContactId).subscribe((roles : Array<ResellerRole>) => {
          var bIsFanAdmin = false;
          if (roles != null)
            roles.forEach(role => {
              if (role.Name == fanAdminName) bIsFanAdmin = true;
            });

          if (bIsFanAdmin)
          {
            // Contact already is a fanmanager, ask confirmation before add
            this.confirmService.confirm({
              title: this.translate.instant('DIALOGS.EDIT-RESELLER-CONTACT.CONFIRM.TITLE.EXISTING-MANAGER'),
              message: this.translate.instant('DIALOGS.EDIT-RESELLER-CONTACT.CONFIRM.MESSAGE.EXISTING-MANAGER'),

              okonly: false
            }).subscribe((confirmed:boolean) => {
              if (confirmed)
                this.addContact(data.ContactId);  
            });
          }
          else
          {
            // Contact is not a fanmanager yet
            this.addContact(data.ContactId);  
          }
        })
      }
      else
      {
        // Not adding a fanmanager, so no check needed
        this.addContact(data.ContactId);
      }
    }
    else{
      if (this.resellerContact.IsPrimaryContact && (this.resellerContact.Roles == null || this.resellerContact.Roles.length == 0))
      {
        this.confirmService.confirm({
          title: this.translate.instant('GENERIC.CONFIRM.ERRORS.EDITING-NOT-POSSIBLE'),
          message: this.translate.instant('DIALOGS.EDIT-RESELLER-CONTACT.CONFIRM.ERRORS.MESSAGE.DELETE-ROLLS-PRIMARY-CONTACT-PERSON'),
          okonly: true
        }).subscribe((confirmed:boolean) => {
        });
      }
      else
      {
        this.resellerService.updateResellerContactRoles(this.resellerContact.ContactId, this.resellerContact.Roles).subscribe((data: string) => {
          let msg = data == null ? this.translate.instant('GENERIC.SNACKBAR.ACCOUNT-MODIFIED') : this.translate.instant('GENERIC.SNACKBAR.ACCOUNT-NOT-MODIFIED');
          this.snackBar.open(msg, 'GENERIC.SNACKBAR.CLOSE');

          this.dialogRef.close(true);
        });
      }
    }

    
  }

  addContact(contactId)
  {
    this.resellerService.addResellerContact(this.resellerId, contactId, this.resellerContact.Roles).subscribe((data: string) => {
      let msg = data == null ? this.translate.instant('GENERIC.SNACKBAR.ACCOUNT-ADDED') : this.translate.instant('GENERIC.SNACKBAR.ACCOUNT-NOT-ADDED');
      this.snackBar.open(msg, 'GENERIC.SNACKBAR.CLOSE');

      this.dialogRef.close(true);
    });
  }
}
