import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { ACLService } from './api/aCL.service';
import { AccountManagerService } from './api/accountManager.service';
import { AccountingDimensionService } from './api/accountingDimension.service';
import { ActivityLogService } from './api/activityLog.service';
import { ApplicationModuleService } from './api/applicationModule.service';
import { AssignmentService } from './api/assignment.service';
import { AssignmentPolicyService } from './api/assignmentPolicy.service';
import { BlacklistService } from './api/blacklist.service';
import { CacheService } from './api/cache.service';
import { CashRegisterService } from './api/cashRegister.service';
import { ConfigService } from './api/config.service';
import { ContactService } from './api/contact.service';
import { ContingentService } from './api/contingent.service';
import { CountryService } from './api/country.service';
import { CrossSellProductService } from './api/crossSellProduct.service';
import { CrossSellProductCategoryService } from './api/crossSellProductCategory.service';
import { CrossSellProductEventService } from './api/crossSellProductEvent.service';
import { CrossSellProductVariantService } from './api/crossSellProductVariant.service';
import { CustomThemeService } from './api/customTheme.service';
import { CustomerService } from './api/customer.service';
import { CustomerCreditChangeService } from './api/customerCreditChange.service';
import { CustomerImportService } from './api/customerImport.service';
import { CustomerMembershipService } from './api/customerMembership.service';
import { CustomerMemoService } from './api/customerMemo.service';
import { CustomerMergeService } from './api/customerMerge.service';
import { DashboardVideoService } from './api/dashboardVideo.service';
import { DeliveryMethodService } from './api/deliveryMethod.service';
import { DiscountService } from './api/discount.service';
import { DistributionChannelService } from './api/distributionChannel.service';
import { DocumentTemplateService } from './api/documentTemplate.service';
import { DynamicTagService } from './api/dynamicTag.service';
import { ETicketTemplateService } from './api/eTicketTemplate.service';
import { EditOrderService } from './api/editOrder.service';
import { EntranceService } from './api/entrance.service';
import { EventService } from './api/event.service';
import { EventMarketplaceSettingService } from './api/eventMarketplaceSetting.service';
import { EventPlacementService } from './api/eventPlacement.service';
import { EventRepurchaseLineService } from './api/eventRepurchaseLine.service';
import { EventTypeService } from './api/eventType.service';
import { EventTypeTemplateService } from './api/eventTypeTemplate.service';
import { ExternalService } from './api/external.service';
import { ExternalTicketService } from './api/externalTicket.service';
import { FantastixService } from './api/fantastix.service';
import { FileService } from './api/file.service';
import { HardcardService } from './api/hardcard.service';
import { HardcardStatusService } from './api/hardcardStatus.service';
import { HardcardTemplateService } from './api/hardcardTemplate.service';
import { IPWhitelistingService } from './api/iPWhitelisting.service';
import { IdVerificationService } from './api/idVerification.service';
import { ImageService } from './api/image.service';
import { InfoService } from './api/info.service';
import { InitiativeService } from './api/initiative.service';
import { InvoiceBatchService } from './api/invoiceBatch.service';
import { LanguageService } from './api/language.service';
import { LongRunningOperationService } from './api/longRunningOperation.service';
import { LoyaltyTagService } from './api/loyaltyTag.service';
import { MailTemplateService } from './api/mailTemplate.service';
import { MaintenanceService } from './api/maintenance.service';
import { MarketplacePolicyService } from './api/marketplacePolicy.service';
import { MatchDayPassService } from './api/matchDayPass.service';
import { MatchDayPassPriceService } from './api/matchDayPassPrice.service';
import { MembershipService } from './api/membership.service';
import { MembershipImportService } from './api/membershipImport.service';
import { MembershipRenewalBatchService } from './api/membershipRenewalBatch.service';
import { MembershipTypeService } from './api/membershipType.service';
import { MobileTicketingService } from './api/mobileTicketing.service';
import { NotificationService } from './api/notification.service';
import { OptInQuestionService } from './api/optInQuestion.service';
import { OrderService } from './api/order.service';
import { OrderActionService } from './api/orderAction.service';
import { OrderBlockedService } from './api/orderBlocked.service';
import { OrderDetailsService } from './api/orderDetails.service';
import { OrderFailureService } from './api/orderFailure.service';
import { OrderImportService } from './api/orderImport.service';
import { OrderManagementService } from './api/orderManagement.service';
import { OrderRevisionsService } from './api/orderRevisions.service';
import { PassePartoutService } from './api/passePartout.service';
import { PassePartoutTypeService } from './api/passePartoutType.service';
import { PaymentMethodService } from './api/paymentMethod.service';
import { PendingOrderService } from './api/pendingOrder.service';
import { PendingOrderLineService } from './api/pendingOrderLine.service';
import { PendingReservationService } from './api/pendingReservation.service';
import { PendingReservationLineService } from './api/pendingReservationLine.service';
import { PhotoService } from './api/photo.service';
import { PlacementTypeService } from './api/placementType.service';
import { PostalCodeService } from './api/postalCode.service';
import { PriceCategoryService } from './api/priceCategory.service';
import { PriceComponentService } from './api/priceComponent.service';
import { PriceListService } from './api/priceList.service';
import { PriceVariationService } from './api/priceVariation.service';
import { PricingPolicyService } from './api/pricingPolicy.service';
import { PrintBatchService } from './api/printBatch.service';
import { PrintJobService } from './api/printJob.service';
import { PrintLocationService } from './api/printLocation.service';
import { ProductLimitationService } from './api/productLimitation.service';
import { PurchaseRightService } from './api/purchaseRight.service';
import { RateLimitingService } from './api/rateLimiting.service';
import { ReportService } from './api/report.service';
import { ReportUserTemplateService } from './api/reportUserTemplate.service';
import { RepurchaseService } from './api/repurchase.service';
import { ResellerService } from './api/reseller.service';
import { ReservationService } from './api/reservation.service';
import { ReservationTypeService } from './api/reservationType.service';
import { RuleEngineService } from './api/ruleEngine.service';
import { SaleCategoryService } from './api/saleCategory.service';
import { SalesChannelService } from './api/salesChannel.service';
import { SalesEntityModificationService } from './api/salesEntityModification.service';
import { SalesGroupService } from './api/salesGroup.service';
import { SalesPeriodService } from './api/salesPeriod.service';
import { SalesPeriodRepurchaseLineService } from './api/salesPeriodRepurchaseLine.service';
import { ScanRegistrationService } from './api/scanRegistration.service';
import { SearchOrdersService } from './api/searchOrders.service';
import { SeasonService } from './api/season.service';
import { SectorService } from './api/sector.service';
import { SoftcardTemplateService } from './api/softcardTemplate.service';
import { StadiumPlanSectionService } from './api/stadiumPlanSection.service';
import { StatisticsService } from './api/statistics.service';
import { SubscriptionProlongationService } from './api/subscriptionProlongation.service';
import { SubscriptionTypeService } from './api/subscriptionType.service';
import { SystemConfigurationSettingsService } from './api/systemConfigurationSettings.service';
import { SystemSettingsService } from './api/systemSettings.service';
import { TagService } from './api/tag.service';
import { TenantShopService } from './api/tenantShop.service';
import { TestService } from './api/test.service';
import { TicketService } from './api/ticket.service';
import { TicketTextService } from './api/ticketText.service';
import { TicketTypeService } from './api/ticketType.service';
import { TranslationService } from './api/translation.service';
import { UserService } from './api/user.service';
import { VatRateService } from './api/vatRate.service';
import { VenueService } from './api/venue.service';
import { VenueBuildingBlockService } from './api/venueBuildingBlock.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
