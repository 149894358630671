import { Component, EventEmitter, Input, OnInit, Output, ViewChild} from "@angular/core";
import { EditOrderService, OrderActionService, OrderDetailsService, OrderLineRevision, OrderLineViewWithPricing, OrderManagementService, OrderRevisionsService, OrderService, TicketSwapStatusEnum } from "../../../api";
import { ApplicationModuleHelperService } from "../../../services/application-module.service";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { EditOrderLineComponent } from "../../../dialogs/edit-order-line/edit-order-line.component";
import { TranslateService } from "@ngx-translate/core";
import { HttpLoaderService } from "@tymes4-shared";

@Component({
    selector: 'app-order-detail-tabs',
    templateUrl: './order-detail-tabs.component.html'
})
export class OrderDetailTabsComponent implements OnInit {
    @ViewChild('paymentOverview') paymentOverview;
    public isIbaActive: boolean = false;
    public secondaryTicketingActive: boolean = false;
    public _isInEditMode: boolean = false;
    public orderRevisions: OrderLineRevision[];
    public isSecondaryShop: boolean = false;
    public secondaryOrderLines: any;

    @Output() orderLineEdited = new EventEmitter();
    @Input() isSecondaryOrder: boolean;
    @Input() orderDetails: any;
    @Input() set isInEditMode(value: boolean){
      this._isInEditMode = value;
    }
    get isInEditMode(): boolean {
      return this._isInEditMode;
    }

    constructor(
      private readonly applicationModuleHelperService: ApplicationModuleHelperService,
      private readonly dialog: MatDialog,
      private readonly translate: TranslateService,
      private readonly orderDetailsService: OrderDetailsService,
      private readonly editOrderService: EditOrderService,
      private readonly orderRevisionsService: OrderRevisionsService,
      private readonly orderActionService: OrderActionService,
      private orderService: OrderService,
      private loader: HttpLoaderService,
    ) { }

    ngOnInit(): void {
      this.isSecondaryShop = this.orderDetails.HasSecondaryTicketStatus;

      this.applicationModuleHelperService.getActiveModules().subscribe((activeModules) => {
        if (activeModules !== null) {
          this.isIbaActive = activeModules.some((module) => module.toUpperCase() == 'IBA')
        }
      });

      this.orderRevisionsService.getOrderRevisions(this.orderDetails.Id).subscribe(revisions => {
        this.orderRevisions = revisions;
      });

      if (this.isSecondaryShop && this.isSecondaryOrder) {
        this.orderService.getSecondaryOrderDetails(this.orderDetails.Id).subscribe(resp => {
          this.secondaryOrderLines = resp.SecondaryOrderLines;
        });
      }

      this.orderDetails.OrderLines.forEach(orderLine => {
        const acceptedStatuses = [TicketSwapStatusEnum.Offered, TicketSwapStatusEnum.Sold];
        if (orderLine.TicketSwapStatus != null && acceptedStatuses.includes(orderLine.TicketSwapStatus)) {
          this.secondaryTicketingActive = true;
        }
      })
    }

    onTabChange(e) {
      if (e.selectedTab.id === 'payment-history') {
        this.paymentOverview.loadPaymentHistoryForOrder(this.orderDetails.Id);
      }
    }

    openEditOrderLineDialog(e: OrderLineViewWithPricing){
      const dialogRef: MatDialogRef<any> = this.dialog.open(EditOrderLineComponent, {
            width: '95%',
            maxWidth: '100%',
            disableClose: true,
            data: {
            title: this.translate.instant('COMPONENTS.CUSTOMER-TICKETS.OPEN-ORDER-DIALOG.TITLE.ORDER-DETAILS'),
            isReadOnly: true,
            orderLine: e,
            orderCustomerId: this.orderDetails.CustomerId
          }
      });
      dialogRef.afterClosed()
        .subscribe(_ => {
          this.loader.open();
          this.orderLineEdited.emit();
          this.orderRevisionsService.getOrderRevisions(this.orderDetails.Id).subscribe(revisions => {
            this.orderRevisions = revisions;
            this.loader.close();
          });
        });
    }

    creditOrderLine(e) {
      this.loader.open();
      this.editOrderService.creditOrderLine(e).subscribe(() => {
        this.orderDetailsService.getOrderDetailsNew(this.orderDetails.Id, true).subscribe(resp => {
          this.orderDetails.OrderLines = resp.OrderLines;
          this.orderRevisionsService.getOrderRevisions(this.orderDetails.Id).subscribe(revisions => {
            this.orderRevisions = revisions;
            this.loader.close();
          });
        });
      });
    }

    removeDiscountCode(discountCodeId: number) {
      this.orderActionService.removeDiscountCodes(this.orderDetails.Id, discountCodeId).subscribe();
    }
}
